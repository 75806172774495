import React from "react";

import Timer from "../HomePage/LoginForm/Timer";

import {
  StyledContainer,
  StyledFormGroup,
  StyledAddInputButton,
  StyledRadioButtons,
  StyledItem,
  StyledSubmitButton,
  StyledLegalFormContainer,
  StyledPersonalButton,
  StyledLegalButton,
  StyledStepWrapper,
} from "./UserNavigationStyle";

import { Form, Input, Select, Radio } from "antd";

const { Option } = Select;

export const getSteps = ({
  legalForm,
  handleChange,
  inputs,
  removeInput,
  getInput,
  radio,
  pinOptions,
  radioHandler,
  showTimerHandler,
  showTimer,
  disableTimer,
  setLegalForm,
  modalForm,
  fileUpload,
  handleSubscriberChange,
}) => [
  {
    title: "",
    id: 1,
    content: (
      <StyledLegalFormContainer>
        <StyledPersonalButton
          legalForm={legalForm}
          onClick={() => setLegalForm(false)}
        >
          ფიზიკური პირი
        </StyledPersonalButton>
        <StyledLegalButton
          legalForm={legalForm}
          onClick={() => setLegalForm(true)}
        >
          იურიდიული პირი
        </StyledLegalButton>
      </StyledLegalFormContainer>
    ),
  },
  {
    title: "",
    id: 2,
    content: (
      <>
        {" "}
        {legalForm && (
          <StyledItem>
            <Form.Item
              label="საიდენტიფიკაციო კოდი"
              rules={[
                {
                  required: true,
                  message: "გთხოვთ შეავსეთ სავალდებულო ველი!",
                },
              ]}
            >
              <Input
                value={modalForm.identificationCode}
                name="identificationCode"
                placeholder="შეიყვანეთ საიდენტიფიკაციო კოდი"
                onChange={handleChange}
              />
            </Form.Item>
          </StyledItem>
        )}
        {legalForm && (
          <StyledItem>
            <Form.Item
              label="კომპანიის დასახელება"
              rules={[
                {
                  required: true,
                  message: "გთხოვთ შეავსეთ სავალდებულო ველი!",
                },
              ]}
            >
              <Input
                name="companyName"
                value={modalForm.companyName}
                placeholder="შეიყვანეთ კომპანიის დასახელება"
                onChange={handleChange}
              />
            </Form.Item>
          </StyledItem>
        )}
        <StyledItem>
          <Form.Item
            label="პირადი ნომერი"
            rules={[
              { required: true, message: "გთხოვთ შეავსეთ სავალდებულო ველი!" },
            ]}
          >
            <Input
              value={modalForm.personalNumber}
              name="personalNumber"
              placeholder="შეიყვანეთ პირადი ნომერი"
              onChange={handleChange}
            />
          </Form.Item>
        </StyledItem>
        <StyledItem>
          <Form.Item
            label="სახელი და გვარი"
            rules={[
              { required: true, message: "გთხოვთ შეავსეთ სავალდებულო ველი!" },
            ]}
          >
            <Input
              value={modalForm.fullName}
              name="fullName"
              placeholder="შეიყვანეთ სახელი და გვარი"
              onChange={handleChange}
            />
          </Form.Item>
        </StyledItem>
      </>
    ),
  },
  {
    title: "",
    id: 3,
    content: (
      <>
        <StyledItem>
          <Form.Item
            label="ელ.ფოსტა"
            rules={[
              { required: true, message: "გთხოვთ შეავსეთ სავალდებულო ველი!" },
            ]}
          >
            <Input
              value={modalForm.email}
              name="email"
              placeholder="შეიყვანეთ ელ.ფოსტა"
              onChange={handleChange}
            />
          </Form.Item>
        </StyledItem>

        <StyledItem>
          <Form.Item
            label="საკონტაქტო ნომერი"
            rules={[
              { required: true, message: "გთხოვთ შეავსეთ სავალდებულო ველი!" },
            ]}
          >
            <Input
              value={modalForm.contactNumber}
              name="contactNumber"
              placeholder="შეიყვანეთ საკონტაქტო ნომერი"
              onChange={handleChange}
            />
          </Form.Item>
        </StyledItem>

        <StyledItem>
          <Form.Item
            label="მისამართი"
            rules={[
              { required: true, message: "გთხოვთ შეავსეთ სავალდებულო ველი!" },
            ]}
          >
            <Input
              value={modalForm.address}
              name="address"
              placeholder="შეიყვანეთ მისამართი"
              onChange={handleChange}
            />
          </Form.Item>
        </StyledItem>
      </>
    ),
  },
  {
    title: "",
    id: 4,
    content: (
      <>
        <StyledItem>
          <Form.Item
            label="სააბონენტო ნომერი"
            name="subscriberNumber"
            rules={[
              { required: true, message: "გთხოვთ შეავსეთ სავალდებულო ველი!" },
            ]}
          >
            <StyledFormGroup>
              <Select
                style={{ width: 120 }}
                onChange={handleSubscriberChange}
                defaultValue="32 (თბილისი)"
              >
                <Option value="jack" style={{ fontSize: "12px" }}>
                  32 (თბილისი)
                </Option>
                <Option value="lucy" style={{ fontSize: "12px" }}>
                  431 (ქუთაისი)
                </Option>
                <Option value="Yiminghe" style={{ fontSize: "12px" }}>
                  422 (ბათუმი)
                </Option>
              </Select>
              <div className="inputs">
                <div className="input__box">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "გთხოვთ შეავსეთ სავალდებულო ველი!",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        width: "180px",
                        marginTop: "0px",
                        padding: "5px",
                      }}
                    />
                  </Form.Item>
                </div>
                {inputs.map((item) => (
                  <div key={item.id} className="input__box">
                    <Form.Item
                      name={item.name}
                      rules={[
                        {
                          required: true,
                          message: "გთხოვთ შეავსეთ სავალდებულო ველი!",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: "180px",
                          marginTop: "0px",
                          padding: "5px",
                        }}
                        placeholder={item.name}
                      />
                    </Form.Item>
                    <button onClick={() => removeInput(item.id)} type="button">
                      <i className="fas fa-trash"></i>
                    </button>
                  </div>
                ))}
              </div>
            </StyledFormGroup>
            <StyledAddInputButton onClick={getInput} type="button">
              დამატება
            </StyledAddInputButton>
          </Form.Item>
        </StyledItem>

        <StyledItem>
          <Form.Item
            label="დონორი ოპერატორი"
            rules={[
              { required: true, message: "გთხოვთ შეავსეთ სავალდებულო ველი!" },
            ]}
          >
            <Input
              name="operator"
              placeholder="შეიყვანეთ დონორი ოპერატორი"
              onChange={handleChange}
              value={modalForm.operator}
            />
          </Form.Item>
        </StyledItem>

        <StyledItem>
          <Form.Item
            label="ფაილის ატვირთვა (PDF)"
            rules={[
              { required: true, message: "გთხოვთ შეავსეთ სავალდებულო ველი!" },
            ]}
          >
            <div className="button-wrapper">
              <span className="label">ფაილის მიმაგრება</span>
              <input
                type="file"
                name="upload"
                id="upload"
                className="upload-box"
                onChange={fileUpload}
              />
            </div>
          </Form.Item>
        </StyledItem>

        <StyledItem>
          <Form.Item label="PIN-ის მიღების მისამართი:" name="pin">
            <StyledRadioButtons>
              <Radio.Group
                options={pinOptions}
                onChange={radioHandler}
                value={radio.value1}
              />
            </StyledRadioButtons>
            <Timer
              showTimerHandler={showTimerHandler}
              showTimer={showTimer}
              disableTimer={disableTimer}
              modal={true}
            />
          </Form.Item>
        </StyledItem>
      </>
    ),
  },
];
