import styled from "styled-components";

export const StyledBar = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 2px solid #f7f7f7;
  padding: 5px;
  border-radius: 3px;
  width: 100%;
  padding-bottom: 10px;
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 7px;
    @media (max-width: 550px) {
      flex-direction: column;
    }
  }
  .left {
    display: flex;
    align-items: center;
  }
  .buttons {
    display: flex;
    align-items: center;
  }
  .ant-input {
    &::placeholder {
      font-size: 12px;
      color: #ccc;
    }
  }
`;
export const StyledContactImage = styled.div`
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  img {
    width: 100%;
  }
`;
export const StyledContactItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  border: 2px solid #f7f7f7;
  padding: 5px;
  border-radius: 3px;
  position: relative;
  width: 100%;
  @media (max-width: 550px) {
    flex-direction: column;
  }
  .nickname__number {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    h4 {
      margin-left: 5px;
      font-size: 12px;
      color: #7f848a;
    }
  }
  .left__side {
    display: flex;
    align-items: center;
  }
`;

export const StyledSmsSendButton = styled.button`
  background-color: #004592;
  color: #fff;
  border: 0;
  border-radius: 3px;
  padding: 7px;
  cursor: pointer;
  font-size: 12px;
  width: 110px;
  font-family: "BOG 2017 Headline";
  transition: 300ms;
  &:hover {
    color: #ccc;
  }
`;

export const StyledButton = styled.button`
  background-color: ${(p) => (p.red ? "#eb0028" : "#004592")};
  color: #fff;
  border: 0;
  border-radius: 3px;
  padding: 7px;
  cursor: pointer;
  font-size: 12px;
  ${(p) => p.red && `width:110px`};
  font-family: "BOG 2017 Headline";
  margin-left: 5px;
  transition: 300ms;
  &:hover {
    color: #ccc;
  }
`;

export const StyledCheckbox = styled.div`
  border-radius: 2px;
  background-image: none;
  border: ${(p) => (p.width ? " 1px solid #d9d9d9 " : "none")};
  width: ${(p) => (p.width ? "190px" : "10px")};
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 25px;
  margin-left: 5px;
  margin-top: ${(p) => (p.absolute ? "-0;" : "-20px")}; /* The container */
  .container {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 12px;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    border: 1px solid #004592;
    background: #fff;
    border-radius: 3px;
    margin-top: 1px;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #004592;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
