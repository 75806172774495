import React, { useState } from "react";
import {
  StyledBar,
  StyledContainer,
  StyledNumberBox,
  StyledHead,
  StyledBody,
  StyledColumn,
} from "./Styles/NumberSettingsStyle";
import { EditOutlined, CloseOutlined } from "@ant-design/icons";
import { Input } from "antd";
const { Search } = Input;
const NumberSettings = () => {
  const [state, setState] = useState({
    items: [
      {
        id: 1,
        number: "995323100100",
        password: "L7mSLNc7",
        sipServer: "sip.telecom1.ge",
        user: "995323100100",
      },
      {
        id: 2,
        number: "995323100100",
        password: "L7mSLNc7",
        sipServer: "sip.telecom1.ge",
        user: "995323100100",
      },
    ],
    edited: {},
  });
  const [active, setActive] = useState(null);

  const editItem = ({ id, value }) => {
    console.log(id, value); // id = item id | value = item password
    setState({
      ...state,
      edited: {
        ...state.edited,
        id,
        password: value,
      },
    });
  };
  return (
    <div>
      <StyledBar>
        <Search
          placeholder="შეიყვანეთ ნომერი"
          onSearch={(value) => console.log(value)}
          style={{ width: 200 }}
        />
      </StyledBar>
      <StyledContainer>
        {state.items.map((item) => {
          const { id } = item;
          return (
            <StyledNumberBox active={active === item.id}>
              <StyledHead>
                <h4>{item.number}</h4>
                {active !== item.id ? (
                  <button
                    className="edit__button"
                    onClick={() => setActive(item.id)}
                  >
                    <EditOutlined
                      style={{ fontSize: "20px", color: "#004592" }}
                    />
                  </button>
                ) : (
                  <button
                    className="edit__button"
                    onClick={() => setActive(null)}
                  >
                    <CloseOutlined
                      style={{ fontSize: "20px", color: "#004592" }}
                    />
                  </button>
                )}
              </StyledHead>
              <StyledBody>
                <StyledColumn column={false}>
                  <h4 className="blue">პაროლი:</h4>
                  {item.id === active ? (
                    <input
                      className="edit__password"
                      value={item.password}
                      onChange={(e) => {
                        const { value } = e.target;
                        editItem({ id, value });
                      }}
                    />
                  ) : (
                    <h4>{item.password}</h4>
                  )}
                </StyledColumn>
                <StyledColumn column={false}>
                  <h4 className="blue">SIP სერვერი:</h4>
                  <h4>{item.sipServer}</h4>
                </StyledColumn>
                <StyledColumn column={false}>
                  <h4 className="blue">მომხმარებელი:</h4>
                  <h4>{item.user}</h4>
                </StyledColumn>
                {item.id === active && (
                  <StyledColumn column={true}>
                    <input className="edit__input" placeholder="ახალი პაროლი" />
                    <input
                      className="edit__input"
                      placeholder="გაიმეორეთ პაროლი"
                    />
                    <button className="edit__button">შენახვა</button>
                  </StyledColumn>
                )}
              </StyledBody>
            </StyledNumberBox>
          );
        })}
      </StyledContainer>
    </div>
  );
};

export default NumberSettings;
