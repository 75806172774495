import styled from "styled-components";

export const StyledFormGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 10px;
  ${(p) => (p.buttons ? null : "  flex-direction: column;")};
  label {
    font-family: "BOG 2017 Headline";
    font-size: 12px;
    color: #5f656c;
  }
  input {
    width: 100%;
    padding: 8px;
    border: 2px solid #f1f4f8;
    border-radius: 3px;
    margin-top: 5px;
    font-size: 12px;
    color: #5f656c;
    &::placeholder {
      font-size: 12px;
      color: #ccc;
    }
  }
  button {
    background-color: #004592;
    color: #fff;
    padding: 6px;
    border: 0;
    border-radius: 3px;
    cursor: pointer;
    font-size: 12px;
    width: 49%;
    margin: 2px;
    font-family: "BOG 2017 Headline";
  }
`;
