import React, { useState, useEffect } from "react";

import { DatePicker, Select, Pagination, Table } from "antd";
import { FilePdfOutlined, FileExcelOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "moment/locale/ka";

import {
  StyledBar,
  StyledButton,
  StyledPickerContainer,
  StyledTableContainer,
  StyledPaginationContainer,
} from "./Styles/A2pExtraction";

const { RangePicker } = DatePicker;
const { Option } = Select;

const columns = [
  {
    title: "შემომავალი",
    dataIndex: "direction",
  },
  {
    title: "თარიღი / დრო",
    dataIndex: "date",
  },
  {
    title: "საიდან",
    dataIndex: "location",
  },
  {
    title: "სად",
    dataIndex: "where",
  },
  {
    title: "ხანგრძლივობა",
    dataIndex: "duration",
  },
  {
    title: "ღირებულება",
    dataIndex: "cost",
  },
];
const A2pExtraction = () => {
  const [state, setState] = useState([
    {
      id: 1,
      direction: "შემომავალი",
      date: "2020-09-14 11:20:41",
      location: "995577442477",
      where: "995323100100",
      duration: "32",
      cost: "-0.01",
    },
    {
      id: 2,
      direction: "შემომავალი",
      date: "2020-09-14 11:12:02",
      location: "995323100100",
      where: "995557131306",
      duration: "44",
      cost: "0.09",
    },
    {
      id: 3,
      direction: "შემომავალი",
      date: "2020-09-14 11:20:41",
      location: "995577442477",
      where: "995323100100",
      duration: "32",
      cost: "-0.01",
    },
    {
      id: 4,
      direction: "შემომავალი",
      date: "2020-09-14 11:12:02",
      location: "995323100100",
      where: "995557131306",
      duration: "44",
      cost: "0.09",
    },
    {
      id: 5,
      direction: "შემომავალი",
      date: "2020-09-14 11:20:41",
      location: "995577442477",
      where: "995323100100",
      duration: "32",
      cost: "-0.01",
    },
    {
      id: 6,
      direction: "შემომავალი",
      date: "2020-09-14 11:12:02",
      location: "995323100100",
      where: "995557131306",
      duration: "44",
      cost: "0.09",
    },
    {
      id: 7,
      direction: "შემომავალი",
      date: "2020-09-14 11:12:02",
      location: "995323100100",
      where: "995557131306",
      duration: "44",
      cost: "0.09",
    },
    {
      id: 8,
      direction: "შემომავალი",
      date: "2020-09-14 11:12:02",
      location: "995323100100",
      where: "995557131306",
      duration: "44",
      cost: "0.09",
    },
    {
      id: 9,
      direction: "შემომავალი",
      date: "2020-09-14 11:12:02",
      location: "995323100100",
      where: "995557131306",
      duration: "44",
      cost: "0.09",
    },
    {
      id: 10,
      direction: "შემომავალი",
      date: "2020-09-14 11:12:02",
      location: "995323100100",
      where: "995557131306",
      duration: "44",
      cost: "0.09",
    },
    {
      id: 11,
      direction: "შემომავალი",
      date: "2020-09-14 11:12:02",
      location: "995323100100",
      where: "995557131306",
      duration: "44",
      cost: "0.09",
    },
  ]);

  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  const { t, i18n } = useTranslation();
  const locale = {
    lang: {
      locale: t("RangePicker"),
      placeholder: "აირჩიეთ თარიღი",
      yearFormat: "YYYY",
      dateFormat: "D M YYYY",
      dayFormat: "D",
      dateTimeFormat: "D M YYYY HH:mm:ss",
    },
  };

  useEffect(() => {
    const nodes = document.querySelectorAll(".ant-picker-input input");
    nodes[0].placeholder = t("RangePickerPlaceholder0");
    nodes[nodes.length - 1].placeholder = t("RangePickerPlaceholder1");
  }, [localStorage.getItem("switched")]);
  return (
    <div>
      <StyledBar>
        <StyledPickerContainer>
          <div className="picker__box">
            <Select
              defaultValue="595607900"
              onChange={handleChange}
              style={{ width: "150px" }}
            >
              <Option value="595607900" style={{ fontSize: "12px" }}>
                595607900
              </Option>
              <Option value="577584877" style={{ fontSize: "12px" }}>
                577584877
              </Option>
            </Select>
          </div>
          <div className="picker__box">
            <Select
              defaultValue="ყველა"
              onChange={handleChange}
              style={{ width: "150px" }}
            >
              <Option value="jack" style={{ fontSize: "12px" }}>
                ყველა
              </Option>
              <Option value="lucy" style={{ fontSize: "12px" }}>
                შემომავალი
              </Option>
              <Option value="გამავალი" style={{ fontSize: "12px" }}>
                გამავალი
              </Option>
            </Select>
          </div>
          <div className="picker__box">
            <RangePicker locale={locale} />
          </div>
        </StyledPickerContainer>
        <div>
          <StyledButton large={true}>დადასტურება</StyledButton>
        </div>
      </StyledBar>
      <StyledTableContainer>
        <Table columns={columns} dataSource={state} size="middle" />
      </StyledTableContainer>
      <StyledPaginationContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <h4>გახარჯული თანხა: 0.085 ₾</h4>
          </div>
          <div>
            <h4 style={{ marginLeft: "15px" }}>
              ბონუსი შემომავალ წუთზე: 0.025 ₾
            </h4>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingRight: "10px",
            }}
          >
            <h5 style={{ marginTop: "7px", paddingRight: "5px" }}> ექსპორტი</h5>
            <h5
              style={{
                textAlign: "center",
              }}
            >
              <FilePdfOutlined
                style={{ fontSize: "27px", color: "#E1191E", marginTop: "7px" }}
              />
            </h5>
            <h5 style={{ marginLeft: "5px", textAlign: "center" }}>
              <FileExcelOutlined
                style={{ fontSize: "27px", color: "#02723B", marginTop: "7px" }}
              />
            </h5>
          </div>
        </div>
      </StyledPaginationContainer>
    </div>
  );
};

export default A2pExtraction;
