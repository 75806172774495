import styled from "styled-components";

export const StyledServicesBox = styled.div`
  width: 285px;
  max-width: 100%;
  height: 450px;
  border: 2px solid #f7f7f7;
  border-radius: 6px;
  padding: 10px;
  margin-top: 25px;
  background: #fff;
  position: relative;
`;
export const StyledModalContainer = styled.div`
  background: red;
`;
export const StyledServicesTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 50px;
  }
  h3 {
    font-family: "BOG 2017 Headline";
    color: #004592;
    font-size: 12px;
  }
`;

export const StyledPacketList = styled.ul`
  padding-top: 20px;
`;
export const StyledPacket = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  color: #343e73;
  border: 1px solid #f7f7f7;
  margin-top: 5px;
  padding: 10px;
  border-radius: 3px;
  font-size: 13px;
  span {
    color: #5f656c;
  }
`;
export const StyledBalanceButton = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;
  font-family: "BOG 2017 Headline";
  color: #004592;
`;
export const StyledBalanceUp = styled.button`
  border: ${(p) => (p.pay ? "1px solid #eb0028" : "1px solid #004592")};
  padding: 7px;
  background-color: ${(p) => (p.pay ? "#eb0028" : "#004592")};
  border-radius: 3px;
  color: #fff;
  font-family: "BOG 2017 Headline";
  font-size: 10px;
  width: ${(p) => (p.pay ? "100%" : "125px")};
  cursor: pointer;
  transition: 300ms;
  margin: 5px;
  &:hover {
    color: #ccc;
  }
`;
export const StyledButtonDetails = styled.button`
  border: 1px solid #f1f4f8;
  padding: 7px;
  background-color: #fff;
  border-radius: 3px;
  color: #5f656c;
  font-family: "BOG 2017 Headline";
  font-size: 10px;
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
  transition: 300ms;
  &:hover {
    background: #004592;
    color: #fff;
    border: 1px solid #004592;
  }
`;
export const StyledPacketController = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;
export const StyledPacketChange = styled.button`
  border: 1px solid #f1f4f8;
  background-color: #fff;
  border-radius: 3px;
  padding: 7px;
  font-family: "BOG 2017 Headline";
  font-size: 10px;
  width: 125px;
  cursor: pointer;
  color: #5f656c;
  transition: 300ms;
  &:hover {
    background: #004592;
    color: #fff;
    border: 1px solid #004592;
  }
`;

export const StyledBar = styled.div`
  width: 885px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: 2px solid #f1f4f8;
  padding: 5px;
  margin-top: 25px;

  @media (max-width: 1000px) {
    justify-content: space-between;
    margin: 10px;
    width: 95%;
  }
`;

export const StyledCheckBox = styled.div`
  position: absolute;
  top: 10px;
  right: 30px;
  cursor: pointer;
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 17px;
    width: 17px;
    border: 1px solid #004592;
    background: #fff;
    border-radius: 3px;
    cursor: pointer;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #004592;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
