import styled from "styled-components";

export const StyledStepWrapper = styled.div`
  .ant-steps-item-process .ant-steps-item-icon {
    background: #004592;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #fff;
    border-color: #004592;
  }
  .ant-steps-item-process .ant-steps-item-icon {
    border-color: #004592;
  }
  .steps-content {
    margin-top: 15px;
  }
  .steps-action {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 480px) {
    .ant-steps-horizontal.ant-steps-label-horizontal {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: initial;
      flex-direction: initial;
      margin-left: 20px;
    }
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: #fff;
  }
  .button-wrapper {
    position: relative;
    width: 180px;
    text-align: center;
    cursor: pointer;
    border: 2px solid #f7f7f7;
    border-radius: 3px;
  }

  .button-wrapper span.label {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background: #fff;
    cursor: pointer;
    color: #7f848a;
    padding: 8px 0;
    text-transform: uppercase;
    font-size: 12px;
    border-radius: 3px;
    font-family: "BOG 2017 Headline";
  }

  #upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  .chose__number {
    font-family: "BOG 2017 Headline";
    color: #7f848a;
    font-size: 12px;
  }
  .center {
    @media (max-width: 414px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const StyledButton = styled.div`
  background-color: ${(p) => (p.green ? "#398439" : "#00418f")};
  padding: 7px;
  border-radius: 3px;
  font-family: "BOG 2017 Headline";
  font-size: 12px;
  margin: 5px;
  cursor: pointer;
  width: 160px;
  color: #fff;
  text-align: center;
  transition: 300ms;
  @media (max-width: 355px) {
    width: 130px;
  }
  &:hover {
    color: #ccc;
  }
`;

export const StyledSelectCategory = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  @media (max-width: 609px) {
    flex-direction: column;
    justify-content: center;
  }
  h4 {
    font-family: "BOG 2017 Headline";
    font-size: 12px;
    color: #7f848a;
  }
`;

export const StyledControll = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  @media (max-width: 355px) {
    flex-direction: column;
    justify-content: center;
  }
  h4 {
    font-family: "BOG 2017 Headline";
    font-size: 12px;
    color: #7f848a;
  }
  input {
    font-size: 12px;
    &::placeholder {
      font-size: 12px;
    }
  }
`;

export const StyledTableContainer = styled.div`
  .ant-table-cell {
    font-size: 12px;
  }
`;

export const StyledServicesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: ${(p) => (p.oneItem ? "center" : "space-between")};
  ${(p) => p.oneItem && "flex-direction:column;"};
  padding-top: 10px;
  padding-bottom: 10px;
  ul {
    li {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 12px;
        color: #7f848a;
        margin: 3px;
      }
    }
  }
  .head {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h4 {
      font-family: "BOG 2017 Headline";
      font-size: 12px;
      color: #00418f;
    }
    img {
      width: 40px;
    }
  }
  .select__service {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledSelectServiceButton = styled.button`
  background: ${(p) => (p.activeService ? "#398439" : "#00418f")};
  border: 0;
  padding: 6px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  color: #fff;
  font-family: "BOG 2017 Headline";
  width: 120px;
  transition: 300ms;
  &:hover {
    color: #ccc;
  }
`;

export const StyledService = styled.div`
  margin-top: 5px;
  box-shadow: ${(p) =>
    p.activeService
      ? " 0px 0px 5px 0px #398439"
      : " 0px 0px 5px 0px rgba(230, 230, 230, 1)"};
  border-radius: 3px;
  padding: 10px;
  margin-top: 15px;
`;

export const StyledPinBox = styled.div`
  padding-top: 15px;
  h4 {
    font-family: "BOG 2017 Headline";
    font-size: 12px;
    color: #00418f;
  }
`;
