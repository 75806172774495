import React from "react";

import { useTranslation } from "react-i18next";

import { Switch } from "antd";

import {
  StyledContainer,
  StyledNavbar,
  StyledUl,
  StyledLi,
  StyledLogo,
} from "./NavbarStyles";

import telecomLogo from "../../assets/png/logoj.jpg";

const Navbar = () => {
  const { t, i18n } = useTranslation();
  function handleLanguageChange(lang) {
    i18n.changeLanguage(lang);
  }

  const switchLang = (checked) => {
    checked
      ? localStorage.setItem("switched", checked)
      : localStorage.removeItem("switched");
    handleLanguageChange(localStorage.getItem("switched") ? "en" : "ka");
  };

  return (
    <StyledContainer>
      <StyledNavbar>
        <StyledLogo>
          <img src={telecomLogo} alt="logo" />
        </StyledLogo>
        <StyledUl hasNonUser={true}>
          <StyledLi>
            <i className="fas fa-phone"></i>{" "}
            <a href="tel:+(995 32) 3 100100" className="telephone">
              +(995 32) 3 100100
            </a>
          </StyledLi>
          <StyledLi>
            <i className="fas fa-envelope"></i> {t("Navbar.1")}
          </StyledLi>
          <StyledLi>
            <Switch
              checkedChildren="GEO"
              unCheckedChildren="ENG"
              checked={localStorage.getItem("switched")}
              onChange={switchLang}
            />
          </StyledLi>
        </StyledUl>
      </StyledNavbar>
    </StyledContainer>
  );
};

export default Navbar;
