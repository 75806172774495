import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 100%;
  background-color: #5c5866;
  padding: 20px;
`;
export const StyledWrapper = styled.div`
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 561px) {
    flex-direction: column;
    text-align: center;
  }
  p {
    color: #fff;
    font-family: "BOG 2018";
    font-size: 12px;
    margin: 5px;
  }
`;

export const StyledIconBoxContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const StyledIconBox = styled.div`
  border: 1px solid #fff;
  border-radius: ${(p) => (p.rocket ? "50px" : "50%")};
  width: ${(p) => (p.rocket ? "100px" : "35px")};
  height: 35px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  cursor: pointer;
  transition: 300ms;
  i {
    font-size: 20px;
  }
  &:hover {
    color: #eb0028;
  }
`;
