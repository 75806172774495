import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 1000px;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
`;

export const StyledAvatar = styled.div`
  margin-top: 10px;
  .avatar {
    width: 210px;
    height: 210px;
    overflow: hidden;
    border-radius: 3px;
    border: 2px solid #004592;
    background: #004592;
    img {
      width: 100%;
      border-radius: 3px;
    }
  }

  .button-wrapper {
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }

  .button-wrapper span.label {
    position: relative;
    z-index: 0;
    display: inline-block;
    width: 100%;
    background: #004592;
    cursor: pointer;
    color: #fff;
    padding: 8px 0;
    text-transform: uppercase;
    font-size: 12px;
    border-radius: 3px;
    font-family: "BOG 2017 Headline";
  }

  #upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50px;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
`;
export const StyledInfoContainer = styled.div`
  width: 100%;
  padding: 10px;
`;

export const StyledInfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  padding: 6px;
  border: 2px solid #f7f7f7;
  span {
    font-size: 12px;
    color: #004592;
  }
  .gray {
    color: #5c5866;
  }
  input {
    border: 0;
    border-bottom: 2px solid #f7f7f7;
    width: 300px;
    &::placeholder {
      text-align: right;
      color: #ccc;
    }
    &:focus {
      outline: none;
    }
  }
`;

export const StyledSubmitBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  input {
    width: 180px;
    padding: 3px;
    border-radius: 3px;
    border: 2px solid #f7f7f7;
    &::placeholder {
      font-size: 12px;
      color: #ccc;
    }
    &:focus {
      outline: none;
    }
  }
`;

export const StyledButtonSave = styled.button`
  font-family: "BOG 2017 Headline";
  color: #004592;
  border-radius: 3px;
  padding: 6px;
  width: 180px;
  font-size: 12px;
  background: #004592;
  color: #fff;
  border: 1px solid #004592;
  cursor: pointer;
  transition: 300ms;
  &:hover {
    color: #ccc;
  }
`;
export const StyledButtonCancel = styled.button`
  background: #fff;
  color: #004592;
  font-family: "BOG 2017 Headline";
  border: 1px solid #004592;
  border-radius: 3px;
  padding: 6px;
  width: 180px;
  font-size: 12px;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  transition: 300ms;
  &:hover {
    color: #ccc;
  }
`;
