import React, { useState, useEffect } from "react";
import { Drawer, Input } from "antd";

import {
  StyledContactImage,
  StyledContactItem,
  StyledSmsSendButton,
  StyledButton,
  StyledBar,
  StyledCheckbox,
} from "./Styles/TelephoneDrawer";

const { Search } = Input;

const TelephoneDrawer = ({
  telephoneDrawer,
  showTelephoneDrawer,
  closeTelephoneDrawer,
  setContactItemHandler,
  contacts,
}) => {
  const [match, setMatch] = useState(false);
  let x = window.matchMedia("(max-width: 700px)");
  function myFunction(x) {
    if (x.matches) {
      // If media query matches
      setMatch(true);
    } else {
      setMatch(false);
    }
  }

  useEffect(() => {
    myFunction(x);
  }, [x.matches]);
  return (
    <Drawer
      title="კონტაქტები"
      placement="right"
      closable={false}
      onClose={closeTelephoneDrawer}
      visible={telephoneDrawer}
      width={match ? 340 : 880}
    >
      <StyledBar>
        <div className="flex">
          <div className="left">
            <Search
              placeholder="კონტაქტის ძებნა"
              onSearch={(value) => console.log(value)}
              style={{ width: 190 }}
            />
            <StyledCheckbox absolute={true} width={true}>
              <label className="container">
                <input type="checkbox" />
                ყველას მონიშვნა
                <span className="checkmark"></span>
              </label>
            </StyledCheckbox>
          </div>
          <div className="buttons">
            <StyledButton red={false}>კონტაქტის დამატება</StyledButton>

            <StyledButton red={true}>წაშლა</StyledButton>
          </div>
        </div>
      </StyledBar>
      {contacts.map((item) => (
        <StyledContactItem key={item.id}>
          <div className="left__side">
            <StyledCheckbox absolute={false} width={false}>
              <label className="container">
                <input type="checkbox" />

                <span className="checkmark"></span>
              </label>
            </StyledCheckbox>
            <StyledContactImage>
              <img src={item.contactImage} />
            </StyledContactImage>
            <div className="nickname__number">
              {item.nickname && <h4>{item.nickname}</h4>}
              <h4>{item.number}</h4>
            </div>
          </div>
          <StyledSmsSendButton onClick={() => setContactItemHandler(item)}>
            SMS გაგზავნა
          </StyledSmsSendButton>
        </StyledContactItem>
      ))}
    </Drawer>
  );
};

export default TelephoneDrawer;
