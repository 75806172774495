import React, { useState } from "react";
import ServicesMenu from "./ServicesMenu";

// სერვისები
import Service from "./ServicesContent/Service/Service";
import MyServices from "./ServicesContent/Service/MyServices";

// ტელეფონი
import Telephone from "./ServicesContent/Telephone/Telephone";
import Extraction from "./ServicesContent/Telephone/Extraction";
import NumberSettings from "./ServicesContent/Telephone/NumberSettings";

// A2P SMS
import A2P from "./ServicesContent/A2p/A2p";
import MassiveSms from "./ServicesContent/A2p/MassiveSms";
import TradeMark from "./ServicesContent/A2p/TradeMark";
import A2PExtraction from "./ServicesContent/A2p/A2pExtraction";
import DatabaseUpload from "./ServicesContent/A2p/DatabaseUpload";
import SMSAPI from "./ServicesContent/A2p/SmsApi";

// გადახდები
import Payments from "./ServicesContent/Payments/Payments";

// დოკუმენტები
import Documents from "./ServicesContent/Documents/Documents";
import Surrender from "./ServicesContent/Documents/Surrender";
import Invoice from "./ServicesContent/Documents/Invoice";

import { StyledContainer, StyledServicesContainer } from "./ServicesStyles";

// მაპი კომპონენტები
const mappings = {
  1: Service,
  2: Telephone,
  3: A2P,
  4: Payments,
  5: Documents,
  6: MyServices,
  8: Extraction,
  9: NumberSettings,
  10: MassiveSms,
  11: TradeMark,
  12: A2PExtraction,
  13: DatabaseUpload,
  14: SMSAPI,
  15: Surrender,
  16: Invoice,
};

const Services = () => {
  const [state, setState] = useState({
    openKeys: ["sub1"],
    servicesMap: [
      {
        id: 1,
        serviceTitle: "ტელეფონი",
        serviceIcon: "https://my.telecom1.ge/img/icons/cellPhone.png",
        packet: "სტანდარტი",
        startDate: "2020-07-09",
        paymentMethod: "სადებეტო",
        limit: 1000,
        balance: 0,
      },
      {
        id: 2,
        serviceTitle: "ინტერნეტი",
        serviceIcon: "https://my.telecom1.ge/img/icons/internet.png",
        packet: "სტარტი",
        remainedPayment: "26",
        startDate: "2020-07-24",
        paymentMethod: "საავანსო",
        balance: 22,
      },
      {
        id: 3,
        serviceTitle: "ტელეფონი",
        serviceIcon: "https://my.telecom1.ge/img/icons/cellPhone.png",
        packet: "სტანდარტი",
        startDate: "2020-07-09",
        paymentMethod: "სადებეტო",
        limit: 1000,
        balance: 0,
      },
      {
        id: 4,
        serviceTitle: "ტელეფონი",
        serviceIcon: "https://my.telecom1.ge/img/icons/cellPhone.png",
        packet: "სტანდარტი",
        startDate: "2020-07-09",
        paymentMethod: "სადებეტო",
        limit: 1000,
        balance: 0,
      },
    ],
    myServices: [
      {
        id: 1,
        serviceTitle: "ინტერნეტი",
        serviceIcon: "https://my.telecom1.ge/img/icons/internet.png",
        packet: "სტარტი",
        remainedPayment: "26",
        startDate: "2020-07-24",
        paymentMethod: "საავანსო",
        balance: 22,
      },
    ],
  });
  const [content, setContent] = useState(1);
  // submenu keys of first level
  const rootSubmenuKeys = ["sub1", "sub2", "sub4"];

  const onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => state.openKeys.indexOf(key) === -1
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setState({ ...state, openKeys });
    } else {
      setState({
        ...state,
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  // სერვისების მენიუ "კომპონენტები"
  const Component = mappings[content];

  const setServiceContent = (contentNumber) => {
    setContent(contentNumber);
  };

  return (
    <StyledContainer>
      <ServicesMenu
        onOpenChange={onOpenChange}
        rootSubmenuKeys={rootSubmenuKeys}
        state={state}
        setServiceContent={setServiceContent}
      />
      <div className="right__side">
        <StyledServicesContainer>
          {Component ? (
            <Component
              servicesMap={state.servicesMap}
              myServicesMap={state.myServices}
            />
          ) : null}
        </StyledServicesContainer>
      </div>
    </StyledContainer>
  );
};

export default Services;
