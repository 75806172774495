import React, { useState } from "react";

import { Form, Modal, Select, message, Steps } from "antd";

import { StyledStepWrapper, StyledButton } from "./AddServiceModalStyle";
import { getServicesStep } from "./getServicesStep";

const { Step } = Steps;
const pinOptions = ["მობილურის ნომერი", "ელ.ფოსტა"];
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};

const AddServiceModal = ({
  addServiceModalVisibleOffHandler,
  modalVisible,
}) => {
  const [phoneBase, setPhoneBase] = useState([
    {
      id: 1,
      number: "310003",
      price: "100₾",
      select: false,
    },
    {
      id: 2,
      number: "310005",
      price: "40₾",
      select: false,
    },
    {
      id: 3,
      number: "310001",
      price: "30₾",
      select: false,
    },
  ]);
  const [services, setServices] = useState({
    bpx: [
      {
        id: 1,
        serviceName: "PBX 10",
        subscriber: "20",
        dayLimit: "30",
        numbersQuantity: "30",
        multiChannelQuantity: true,
        welcome: true,
        callRouting: true,
        callForwarding: false,
        recordingConversations: true,
        smsBrandName: false,
      },
      {
        id: 2,
        serviceName: "PBX 30",
        subscriber: "20",
        dayLimit: "30",
        numbersQuantity: "30",
        multiChannelQuantity: false,
        welcome: true,
        callRouting: true,
        callForwarding: false,
        recordingConversations: true,
        smsBrandName: true,
      },
    ],
    telephone: [
      {
        id: 1,
        serviceName: "სტანდარტი",
        subscriber: "1",
        dayLimit: "30",
        inTheInternalNetwork: "0.01",
        freeCallsOnTheInternalNetwork: "1000 წთ",
        fixedNetwork: "0.06",
        mobileNetwork: "0.10",
        bonus: "0",
        sms: "0.06",
        freeSmsOfDay: "50",
        minimumDeposit: "5",
      },
      {
        id: 2,
        serviceName: "სტანდარტ+",
        subscriber: "1",
        dayLimit: "30",
        inTheInternalNetwork: "0.01",
        freeCallsOnTheInternalNetwork: "ულიმიტო",
        fixedNetwork: "0.06",
        mobileNetwork: "0.10",
        bonus: "0",
        sms: "0.06",
        freeSmsOfDay: "100",
        minimumDeposit: "5",
      },
    ],
  });
  const [selectedService, setSelectedService] = useState({});
  const [activeService, setActiveService] = useState(null);
  const [current, setCurrent] = useState(0);
  const [showTimer, setShowTimer] = useState(false);
  const [disableTimer, setDisableTimer] = useState(false);
  const [modalForm, setModalForm] = useState({
    category: "",
  });

  const categoryChangeHandler = (value) => {
    setModalForm({
      ...modalForm,
      category: value,
    });
    console.log(value);
  };

  // სტეპის ერთი ნაბიჯით უკან
  const prev = () => {
    const currentStep = current - 1;
    setCurrent(currentStep);
  };

  const next = () => {
    const currentStep = current + 1;
    setCurrent(currentStep);
  };

  const selectService = ({ item, id }) => {
    setSelectedService(item);
    setActiveService(id);
  };

  const showTimerHandler = () => {
    setShowTimer(!showTimer);
    if (showTimer !== true) {
      setDisableTimer(true);
      setTimeout(() => {
        setShowTimer(false);
        setDisableTimer(false);
      }, 180000);
    }
    message.success("PIN კოდი გამოგზავნილია!");
  };

  const category = modalForm.category;
  const steps = getServicesStep({
    categoryChangeHandler,
    category,
    phoneBase,
    services,
    selectService,
    activeService,
    selectedService,
    showTimerHandler,
    showTimer,
    disableTimer,
  });
  return (
    <div>
      <Modal
        title="სერვისის დამატება"
        visible={modalVisible}
        onOk={addServiceModalVisibleOffHandler}
        onCancel={addServiceModalVisibleOffHandler}
        centered
        width={680}
        style={{ top: 20 }}
        footer={null}
      >
        <Form {...layout} name="basic" initialValues={{ remember: true }}>
          <StyledStepWrapper>
            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.id} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action">
              {current > 0 && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <StyledButton onClick={() => prev()}>უკან</StyledButton>
                  {current === 2 && (
                    <StyledButton green={true}>გაგზავნა</StyledButton>
                  )}
                </div>
              )}
              {current < steps.length - 1 && (
                <StyledButton type="primary" onClick={() => next()}>
                  შემდეგი
                </StyledButton>
              )}
            </div>
          </StyledStepWrapper>
        </Form>
      </Modal>
    </div>
  );
};

export default AddServiceModal;
