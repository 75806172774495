import React, { useState } from "react";
import { StyledContainer } from "./Styles/SmsApiStyle";
import { Table } from "antd";

const columns = [
  {
    title: "საფირმო სახელწოდება",
    dataIndex: "type",
  },
  {
    title: "API",
    dataIndex: "date",
  },
];

const SmsApi = () => {
  const [state, setState] = useState({
    items: [
      {
        id: 1,
        type: "nike.ge",
        date: "telecom1.ge/api/callback.php?apikey=apikey&messageId=messageId",
      },
    ],
  });
  return (
    <StyledContainer>
      <Table columns={columns} dataSource={state.items} size="middle" />
    </StyledContainer>
  );
};

export default SmsApi;
