import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Steps, Button, message, Checkbox } from "antd";
import {
  StyledContainer,
  StyledFormContainer,
  DisplayFlex,
  StyledFormTitle,
  StyledFormSwitch,
  StyledFormGroup,
  StyledFormWrapper,
  StyledButton,
  StyledInput,
  StyledLabel,
  StyledPasswordForgot,
  StyledPasswordForgotContainer,
  StyledShowPassword,
} from "./LoginFormStyles";

import { getSteps } from "./getSteps";
import ForgotPassword from "./ForgotPassword";
const { Step } = Steps;

const LoginForm = () => {
  const [state, setState] = useState({
    loginForm: {
      name: "",
      password: "",
    },
    registerForm: {
      personalNumber: "",
      fullName: "",
      user: "",
      currentUser: "",
      legalUser: "",
      userPassword: "",
      legalPassword: "",
      phoneNumber: "",
      email: "",
      identificationCode: "",
      lls: "",
      legalFormSelected: "",
      responsiblePerson: "",
    },
    forgotPasswordForm: {
      email: "",
      phoneNumber: "",
      password: "",
      repeatPassword: "",
    },
    error: {
      name: false,
      password: false,
      personalNumber: false,
      fullName: false,
      user: false,
      currentUser: false,
      legalUser: false,
      userPassword: false,
      legalPassword: false,
      phoneNumber: false,
      email: false,
      identificationCode: false,
      lls: false,
      legalFormSelected: false,
      responsiblePerson: false,
      forgotEmail: false,
      forgotPhoneNumber: false,
      forgotPassword: false,
      forgotRepeatPassword: false,
    },
  });
  const { t, i18n } = useTranslation();
  const [current, setCurrent] = useState(0);

  // შესვლა ან რეგისტრაცია
  const [switchForm, setSwitchForm] = useState(false);

  // ფორმის არჩევა 1) ფიზიკური პირი 2) იურიდიული პირი
  const [radio, setRadio] = useState({
    value1: "1",
    value2: "2",
  });

  // ფიზიკური ან იურიდიული პირის რეგისტრაციის ფორმის სტეიტი
  const [legalForm, setLegalForm] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [showTimer, setShowTimer] = useState(false);
  const [disableTimer, setDisableTimer] = useState(false);

  const [pinNotification, setPinNotification] = useState(false);

  const [forgotPassword, setForgotPassword] = useState(false);

  const error = () => {
    message.error("გთხოვთ აირჩიოთ ფიზიკური ან იურიდიული პირი!");
  };
  const empty = () => {
    message.error("გთხოვთ შეავსოთ სავალდებულო ველი!");
  };

  // სტეპის ერთი ნაბიჯით წინ
  const next = () => {
    // თუ არჩეული არარის რეგისტრაციის ფორმა
    // თუ არჩეული არარის რეგისტრაციის ფორმა
    if (legalForm === false) {
      error();
    } else {
      if (current === 0) {
        const currentStep = current + 1;
        setCurrent(currentStep);
      } // current 1 ის 1 ფორმა
      else if (current === 1) {
        if (legalForm === 1) {
          if (
            state.registerForm.personalNumber.length &&
            state.registerForm.fullName.length
          ) {
            const currentStep = current + 1;
            setCurrent(currentStep);
          } else {
            empty();
            setState({
              ...state,
              error: {
                ...state.error,
                personalNumber: !state.registerForm.personalNumber.length,
                fullName: !state.registerForm.fullName.length,
              },
            });
          }
        } // current 1 ის 2 ფორმა
        else if (legalForm === 2) {
          if (
            state.registerForm.identificationCode &&
            state.registerForm.lls &&
            state.registerForm.legalFormSelected
          ) {
            const currentStep = current + 1;
            setCurrent(currentStep);
          } else {
            empty();
            setState({
              ...state,
              error: {
                ...state.error,
                identificationCode: !state.registerForm.identificationCode
                  .length,
                lls: !state.registerForm.lls.length,
                legalFormSelected: !state.registerForm.legalFormSelected.length,
              },
            });
          }
        }
      } else if (current === 2) {
        // current 2-ეს 1 ფორმა
        if (legalForm === 1) {
          if (
            state.registerForm.currentUser.length &&
            state.registerForm.userPassword.length
          ) {
            const currentStep = current + 1;
            setCurrent(currentStep);
          } else {
            empty();
            setState({
              ...state,
              error: {
                ...state.error,
                currentUser: !state.registerForm.currentUser.length,
                userPassword: !state.registerForm.userPassword.length,
              },
            });
          }
        } // current 2-ეს 2 ფორმა
        else if (legalForm === 2) {
          if (
            state.registerForm.legalUser &&
            state.registerForm.legalPassword &&
            state.registerForm.responsiblePerson
          ) {
            const currentStep = current + 1;
            setCurrent(currentStep);
          } else {
            empty();
            setState({
              ...state,
              error: {
                ...state.error,
                legalUser: !state.registerForm.legalUser.length,
                legalPassword: !state.registerForm.legalPassword.length,
                responsiblePerson: !state.registerForm.responsiblePerson.length,
              },
            });
          }
        }
      } else if (current === 3) {
        // current 3-ეს 1 ფორმა
        if (legalForm === 1) {
          if (
            state.registerForm.phoneNumber.length &&
            state.registerForm.email.length
          ) {
            const currentStep = current + 1;
            setCurrent(currentStep);
          } else {
            empty();
            setState({
              ...state,
              error: {
                ...state.error,
                phoneNumber: !state.registerForm.phoneNumber.length,
                email: !state.registerForm.email.length,
              },
            });
          }
        } // current 3-ეს 2 ფორმა
        else if (legalForm === 2) {
          if (state.registerForm.phoneNumber && state.registerForm.email) {
            const currentStep = current + 1;
            setCurrent(currentStep);
          } else {
            empty();
            setState({
              ...state,
              error: {
                ...state.error,
                phoneNumber: !state.registerForm.phoneNumber.length,
                email: !state.registerForm.email.length,
              },
            });
          }
        }
      }
    }
  };

  // სტეპის ერთი ნაბიჯით უკან
  const prev = () => {
    const currentStep = current - 1;
    setCurrent(currentStep);
    setState({
      ...state,
      error: {
        name: false,
        password: false,
        personalNumber: false,
        fullName: false,
        user: false,
        currentUser: false,
        legalUser: false,
        userPassword: false,
        legalPassword: false,
        phoneNumber: false,
        email: false,
        identificationCode: false,
        lls: false,
        responsiblePerson: false,
      },
    });
    setDisableTimer(false);
    setShowTimer(false);
  };

  // შესვლის ფორმის საბმითი
  const handleSubmit = (e) => {
    e.preventDefault();
    // ერორ სტეიტი
    if (state.loginForm.name && state.loginForm.password.length) {
      console.log(state.loginForm);
    } else {
      empty();
      setState({
        ...state,
        error: {
          ...state.error,
          name: !state.loginForm.name.length,
          password: !state.loginForm.password.length,
        },
      });
    }
  };

  // ავტორიზაციის ინპუტების ჩეინჯერი
  const handleChange = (e) => {
    setState({
      ...state,
      loginForm: {
        ...state.loginForm,
        [e.target.name]: e.target.value,
      },
      error: {
        ...state.error,
        [e.target.name]: false,
      },
    });
  };

  // ფიზიკური პირის საბმიტი
  const handleOneFormSubmit = () => {
    message.success("თქვენ წარმატებით გაიარეთ რეგისტრაცია!");
    console.log("One Form");
    const {
      personalNumber,
      fullName,
      currentUser,
      userPassword,
      phoneNumber,
      email,
    } = state.registerForm;
  };

  // იურიდიული პირის საბმიტი
  const handleTwoFormSubmit = () => {
    message.success("თქვენ წარმატებით გაიარეთ რეგისტრაცია!");
    console.log("Two Form");
    const {
      identificationCode,
      legalFormSelected,
      lls,
      responsiblePerson,
      legalUser,
      legalPassword,
      phoneNumber,
      email,
    } = state.registerForm;
  };

  // რეგისტრაციის ინპუტების ჩეინჯერი
  const handleRegisterChange = (e) => {
    setState({
      ...state,
      registerForm: {
        ...state.registerForm,
        [e.target.name]: e.target.value,
      },
      error: {
        ...state.error,
        [e.target.name]: false,
      },
    });
  };

  // ForgotPassword პაროლის აღდგენის ინპუტების ჩეინჯერი
  const handleForgotPassword = (e) => {
    setState({
      ...state,
      forgotPasswordForm: {
        ...state.forgotPasswordForm,
        [e.target.name]: e.target.value,
      },
    });
  };

  // ForgotPassword პაროლის აღდგენის ფორმის საბმიტი
  const handleOnSubmitForgotPassword = (e) => {
    e.preventDefault();
    if (forgotPassword) {
      if (
        state.forgotPasswordForm.password &&
        state.forgotPasswordForm.repeatPassword
      ) {
        // დადასტურებული ინფორმაცია
        console.log(state.forgotPasswordForm);
      } else {
        empty();
        setState({
          ...state,
          error: {
            ...state.error,
            forgotPassword: !state.forgotPasswordForm.password.length,
            forgotRepeatPassword: !state.forgotPasswordForm.repeatPassword
              .length,
            forgotPhoneNumber:
              pinNotification === 1
                ? !state.forgotPasswordForm.phoneNumber.length
                : false,
            forgotEmail:
              pinNotification === 2
                ? !state.forgotPasswordForm.email.length
                : false,
          },
        });
      }
    }
  };

  // შესვლა ან რეგისტრაცია
  const switchFormHandler = () => {
    setSwitchForm(!switchForm);
  };

  // ფიზიკური ან იურიდიული პირის რეგისტრაციის ფორმის არჩევა
  const radioHandler = (e) => {
    setRadio({
      value1: e.target.value,
    });
    console.log(e.target.value);
    if (e.target.value === "ფიზიკური პირი") {
      setLegalForm(1);
    } else if (e.target.value === "იურიდიული პირი") {
      setLegalForm(2);
    } else if (e.target.value === "მობილურის ნომერი") {
      setPinNotification(1);
    } else if (e.target.value === "ელ.ფოსტა") {
      setPinNotification(2);
    }
  };

  const radioForgotPasswordHandler = (e) => {
    if (e.target.value === "მობილურის ნომერი") {
      setPinNotification(1);
    } else if (e.target.value === "ელ.ფოსტა") {
      setPinNotification(2);
    } else if (e.target.value === "Email") {
      setPinNotification(2);
    } else if (e.target.value === "Mobile Number") {
      setPinNotification(1);
    }
    console.log(radio);
  };

  function onChange(value) {
    setState({
      ...state,
      registerForm: {
        ...state.registerForm,
        legalFormSelected: value,
      },
    });
  }

  const showPasswordHandler = () => setShowPassword(!showPassword);

  // ციფრების ვალიდაცია, ჩეინჯერი მათთვის რომელიც მხოლოდ ციფრები შედის
  const onHandleNumberChange = (e) => {
    let number = e.target.value;
    let regexp = /^[0-9\b]+$/;
    if (number === "" || regexp.test(number)) {
      setState({
        ...state,
        registerForm: {
          ...state.registerForm,
          [e.target.name]: number,
        },
      });
    }
  };

  const showTimerHandler = () => {
    setShowTimer(!showTimer);
    if (showTimer !== true) {
      setDisableTimer(true);
      setTimeout(() => {
        setShowTimer(false);
        setDisableTimer(false);
      }, 180000);
    }
    /**    if (showTimer !== true) {
      setDisableTimer(true);
      setTimeout(() => {
        setDisableTimer(false);
      }, 10000);
    } */
    message.success("PIN კოდი გამოგზავნილია!");
  };

  const forgotePassword = () => {
    setForgotPassword(!forgotPassword);
    setDisableTimer(false);
    setShowTimer(false);
  };
  // სტეპები
  const steps = getSteps({
    state,
    legalForm,
    radioHandler,
    radio,
    handleRegisterChange,
    onChange,
    showPasswordHandler,
    showPassword,
    onHandleNumberChange,
    showTimer,
    showTimerHandler,
    pinNotification,
    disableTimer,
    forgotPassword,
    t,
    i18n,
  });

  return (
    <StyledContainer formSwitch={switchForm} forgotPassword={forgotPassword}>
      <StyledFormContainer>
        <StyledFormTitle>
          {switchForm ? (
            legalForm === false ? (
              "რეგისტრაცია"
            ) : legalForm === 1 ? (
              "ფიზიკური პირის რეგისტრაცია"
            ) : (
              "იურიდიული პირის რეგისტრაცია"
            )
          ) : (
            <p>{t("SignInForm.SignInTitle")}</p>
          )}
        </StyledFormTitle>
        {switchForm ? (
          <div className="step__wrapper">
            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action">
              {current > 0 && <Button onClick={() => prev()}>უკან</Button>}
              {current < steps.length - 1 && (
                <Button type="primary" onClick={() => next()}>
                  შემდეგი
                </Button>
              )}

              {current === steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={
                    legalForm === 1 ? handleOneFormSubmit : handleTwoFormSubmit
                  }
                >
                  რეგისტრაცია
                </Button>
              )}
            </div>
          </div>
        ) : (
          <form
            onSubmit={
              forgotPassword
                ? handleOnSubmitForgotPassword
                : legalForm === 1
                ? handleOneFormSubmit
                : legalForm === 2
                ? handleTwoFormSubmit
                : handleSubmit
            }
          >
            <StyledFormWrapper>
              {forgotPassword ? (
                <ForgotPassword
                  showPassword={showPassword}
                  showPasswordHandler={showPasswordHandler}
                  radioHandler={radioHandler}
                  radio={radio}
                  showTimerHandler={showTimerHandler}
                  showTimer={showTimer}
                  disableTimer={disableTimer}
                  pinNotification={pinNotification}
                  state={state}
                  forgotPassword={forgotPassword}
                  radioForgotPasswordHandler={radioForgotPasswordHandler}
                  handleForgotPassword={handleForgotPassword}
                  handleOnSubmitForgotPassword={handleOnSubmitForgotPassword}
                />
              ) : (
                <>
                  {" "}
                  <StyledFormGroup>
                    <StyledLabel>{t("SignInForm.User")}</StyledLabel>
                    <StyledInput
                      name="name"
                      onChange={handleChange}
                      isValid={state.error.name}
                      placeholder={t("SignInForm.UserInput")}
                    />
                  </StyledFormGroup>
                  <StyledFormGroup>
                    <StyledLabel>{t("SignInForm.Password")}</StyledLabel>
                    <StyledInput
                      name="password"
                      onChange={handleChange}
                      isValid={state.error.password}
                      placeholder={t("SignInForm.PasswordInput")}
                      type={showPassword ? "text" : "password"}
                    />
                    <StyledShowPassword
                      type="button"
                      onClick={showPasswordHandler}
                      showPassword={showPassword}
                    >
                      <i className="fas fa-eye"></i>
                    </StyledShowPassword>
                  </StyledFormGroup>
                  <StyledFormGroup>
                    <StyledPasswordForgotContainer>
                      <Checkbox>{t("SignInForm.Remember")}</Checkbox>
                      <StyledPasswordForgot onClick={forgotePassword}>
                        {t("SignInForm.PasswordRecovery")}
                      </StyledPasswordForgot>
                    </StyledPasswordForgotContainer>
                  </StyledFormGroup>
                </>
              )}
            </StyledFormWrapper>
            <StyledFormGroup>
              <DisplayFlex center={true}>
                <StyledButton type="submit">
                  {forgotPassword ? (
                    <>{t("SignInForm.Save")}</>
                  ) : (
                    <>{t("SignInForm.SignInButton")}</>
                  )}
                </StyledButton>
              </DisplayFlex>
            </StyledFormGroup>
          </form>
        )}
        <DisplayFlex center={true}>
          <StyledFormSwitch>
            {switchForm ? (
              <span onClick={switchFormHandler}>
                {t("SignInForm.SignInLink")}
              </span>
            ) : forgotPassword ? (
              <span onClick={forgotePassword}>
                {t("SignInForm.CancelLink")}
              </span>
            ) : (
              <span onClick={switchFormHandler}>
                {t("SignInForm.SignUpLink")}
              </span>
            )}
          </StyledFormSwitch>
        </DisplayFlex>
      </StyledFormContainer>
    </StyledContainer>
  );
};

export default LoginForm;
