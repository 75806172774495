import React, { useState, useEffect } from "react";
import { EditOutlined } from "@ant-design/icons";
import {
  StyledContainer,
  StyledAvatar,
  StyledInfoContainer,
  StyledInfoBox,
  StyledSubmitBox,
  StyledButtonCancel,
  StyledButtonSave,
} from "./ProfilePageStyle";
const ProfilePage = () => {
  const [state, setState] = useState({
    userInformation: {
      subscriberNumber: "364829",
      subscriber: "დათო მამასახლისი",
      identificationCode: "405374063",
      dateOfBirth: "30/10/2000",
      address: "საქართველო, ქუთაისი - სულხან საბას გამზირი",
      mobileNumber: "595607900",
      phoneNumber: "0323100100",
      email: "datomamasakhlisi@gmail.com",
      idNumber: "6000112031241",
      gender: "მამრობითი",
    },
    edited: {
      editAddress: "",
      editMobileNumber: "",
      editPhoneNumber: "",
      editEmail: "",
      editPassword: "",
      editRepeatPassword: "",
    },
  });

  const [active, setActive] = useState("");

  const editItem = (e) => {
    setState({
      ...state,
      edited: {
        ...state.edited,
        [e.target.name]: e.target.value,
      },
    });
  };

  const setActiv = (prop) => {
    active === prop ? setActive("") : setActive(prop);
  };

  const {
    subscriberNumber,
    subscriber,
    identificationCode,
    dateOfBirth,
    address,
    mobileNumber,
    phoneNumber,
    email,
    idNumber,
    gender,
    password,
  } = state.userInformation;

  const {
    editAddress,
    editMobileNumber,
    editPhoneNumber,
    editEmail,
    editPassword,
    editRepeatPassword,
  } = state.edited;

  // რომელიც დაედიტებულია
  const filtered = Object.keys(state.edited).reduce((acc, key) => {
    if (state.edited[key]) {
      return { ...acc, [key]: state.edited[key] };
    }
    return acc;
  }, {});
  return (
    <StyledContainer>
      <StyledAvatar>
        <div className="avatar">
          <img src="https://www.fssecurities.com/fswp/wp-content/uploads/2013/12/Person.Ashley.jpg" />
        </div>

        <div class="button-wrapper">
          <span class="label">ფოტოს ატვირთვა</span>
          <input
            type="file"
            name="upload"
            id="upload"
            class="upload-box"
            placeholder="ფოტოს ატვირთვა"
            onChange={(e) => console.log(e.target.files[0])}
          />
        </div>
      </StyledAvatar>
      <StyledInfoContainer>
        <StyledInfoBox>
          <span>აბონენტის ნომერი:</span>
          <span className="gray">{subscriberNumber}</span>
        </StyledInfoBox>
        <StyledInfoBox>
          <span>აბონენტი:</span>
          <span className="gray">{subscriber}</span>
        </StyledInfoBox>
        <StyledInfoBox>
          <span>საიდენთიფიკაციო კოდი:</span>
          <span className="gray">{identificationCode}</span>
        </StyledInfoBox>
        <StyledInfoBox>
          <span>დაბადების თარიღი:</span>
          <span className="gray">{dateOfBirth}</span>
        </StyledInfoBox>
        <StyledInfoBox>
          <span>პირადი ნომერი:</span>
          <span className="gray">{idNumber}</span>
        </StyledInfoBox>
        <StyledInfoBox>
          <span>სქესი:</span>
          <span className="gray">{gender}</span>
        </StyledInfoBox>
        <StyledInfoBox>
          <span>მისამართი:</span>
          <span className="gray">
            {active === "address" ? (
              <input
                type="text"
                name="editAddress"
                placeholder={address}
                value={editAddress}
                onChange={editItem}
              />
            ) : (
              address
            )}
            <EditOutlined
              style={{ fontSize: "18px" }}
              onClick={() => setActiv("address")}
            />
          </span>
        </StyledInfoBox>
        <StyledInfoBox>
          <span>მობილურის ნომერი:</span>
          <span className="gray">
            {active === "mobileNumber" ? (
              <input
                type="text"
                name="editMobileNumber"
                placeholder={mobileNumber}
                value={editMobileNumber}
                onChange={editItem}
              />
            ) : (
              mobileNumber
            )}{" "}
            <EditOutlined
              style={{ fontSize: "18px" }}
              onClick={() => setActiv("mobileNumber")}
            />
          </span>
        </StyledInfoBox>
        <StyledInfoBox>
          <span>ტელეფონის ნომერი:</span>
          <span className="gray">
            {active === "phoneNumber" ? (
              <input
                type="text"
                name="editPhoneNumber"
                placeholder={phoneNumber}
                value={editPhoneNumber}
                onChange={editItem}
              />
            ) : (
              phoneNumber
            )}{" "}
            <EditOutlined
              style={{ fontSize: "18px" }}
              onClick={() => setActiv("phoneNumber")}
            />
          </span>
        </StyledInfoBox>
        <StyledInfoBox>
          <span>ელ-ფოსტა:</span>
          <span className="gray">
            {active === "email" ? (
              <input
                type="text"
                name="editEmail"
                placeholder={email}
                value={editEmail}
                onChange={editItem}
              />
            ) : (
              email
            )}{" "}
            <EditOutlined
              style={{ fontSize: "18px" }}
              onClick={() => setActiv("email")}
            />
          </span>
        </StyledInfoBox>
        <StyledInfoBox>
          <span>პაროლი:</span>
          <span className="gray">
            {active === "editPassword" ? (
              <input
                type="text"
                name="editPassword"
                placeholder="********"
                value={editPassword}
                onChange={editItem}
              />
            ) : (
              "*******"
            )}{" "}
            <EditOutlined
              style={{ fontSize: "18px" }}
              onClick={() => setActiv("editPassword")}
            />
          </span>
        </StyledInfoBox>
        <StyledInfoBox>
          <span>გაიმეორეთ პაროლი:</span>
          <span className="gray">
            {active === "editRepeatPassword" ? (
              <input
                type="text"
                name="editRepeatPassword"
                placeholder="********"
                value={editRepeatPassword}
                onChange={editItem}
              />
            ) : (
              "*******"
            )}{" "}
            <EditOutlined
              style={{ fontSize: "18px" }}
              onClick={() => setActiv("editRepeatPassword")}
            />
          </span>
        </StyledInfoBox>
        {active.length ? (
          <StyledSubmitBox>
            <div>
              <StyledButtonSave>დამახსოვრება</StyledButtonSave>
              <StyledButtonCancel>გაუქმება</StyledButtonCancel>
            </div>
            <input placeholder="შეიყვანეთ პაროლი" />
          </StyledSubmitBox>
        ) : (
          ""
        )}
      </StyledInfoContainer>
    </StyledContainer>
  );
};

export default ProfilePage;
