import React from "react";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";

import { StyledPinBox } from "./LoginFormStyles";
const Timer = ({
  showTimer,
  showTimerHandler,
  forgotPassword,
  disableTimer,
  modal,
}) => {
  const { t, i18n } = useTranslation();
  const Completionist = () => <span>{t("TimerButtonText")}</span>;
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    }
    return (
      <span>
        {minutes}:{seconds}
      </span>
    );
  };
  return (
    <div>
      <StyledPinBox
        forgotPassword={forgotPassword}
        disableTimer={disableTimer}
        modal={modal}
      >
        <button
          onClick={showTimerHandler}
          className="pin"
          type="button"
          disabled={disableTimer}
        >
          {showTimer ? (
            <Countdown date={Date.now() + 180000} renderer={renderer} />
          ) : (
            <>{t("TimerButtonText")}</>
          )}
        </button>
        <input type="text" className="pin__input" maxLength="4" />
      </StyledPinBox>
    </div>
  );
};

export default Timer;
