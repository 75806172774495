import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 100%;
  background: #004592;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  margin-top: 20px;
  a {
    font-family: "BOG 2017 Headline";
    color: #fff;
    font-size: 12px;
  }
`;
export const StyledNavbar = styled.nav`
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
`;

export const StyledUl = styled.ul`
  display: flex;
  align-items: center;
  margin-top: ${(p) => (p.hasNonUser ? "15px" : "0px")};
  width: 100%;
  ${(p) =>
    p.location &&
    `justify-content:space-between;   @media (max-width: 699px) {
    flex-direction:column;
  }`};
  @media (max-width: 699px) {
    justify-content: center;
  }
  .links {
    display: flex;
    align-items: center;
    @media (max-width: 699px) {
      flex-direction: column;
    }
  }
`;
export const StyledLi = styled.li`
  list-style: none;
  margin: 0px;
  color: #7f848a;
  font-family: "BOG 2017 Headline";
  font-size: 13px;
  margin-right: 15px;
  @media (max-width: 699px) {
    margin-right: 0;
  }
  i {
    color: #eb0028;
  }
  .telephone {
    color: #7f848a;
  }
  a {
    color: #fff;
    font-size: 12px;
    transition: 300ms;
    @media (max-width: 415px) {
      font-size: 10px;
    }
    &:hover {
      color: #ccc;
    }
  }
  span {
    color: #fff;
    font-size: 12px;
    transition: 300ms;
    cursor: pointer;
    @media (max-width: 415px) {
      font-size: 10px;
    }
    &:hover {
      color: #ccc;
    }
  }
  .ant-switch-checked {
    background-color: #eb0028;
    border-radius: 3px;
  }
  .ant-switch {
    border-radius: 3px;
    background-color: #eb0028;
  }

  .ant-switch-handle::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    content: "";
  }
`;

export const StyledAvatar = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid #fff;
  overflow: hidden;
  position: relative;
  img {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
`;

export const StyledTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  padding-left: 30px;
  @media (max-width: 699px) {
    flex-direction: column;
  }
  h4 {
    margin-top: -2px;
    margin-left: 15px;
    margin-top: 5px;
    color: #fff;
    font-family: "BOG 2017 Headline";
    font-size: 12px;
    @media (max-width: 415px) {
      font-size: 10px;
    }
  }
  @media (max-width: 699px) {
    margin-left: -30px;
  }
`;
