import React from "react";
import "antd/dist/antd.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Home} path="/login" />
        <Route component={Profile} path="/profile" />
        <Route component={Dashboard} path="/" />
      </Switch>
    </Router>
  );
};

export default App;
