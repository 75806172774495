import React from "react";
import Navbar from "../components/Navbar/Navbar";
import UserNavigation from "../components/Navbar/UserNavigation";
import Footer from "../components/Footer/Footer";
import ProfilePage from "../components/Profile/ProfilePage";
const Profile = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <div>
        <Navbar />
        <UserNavigation />
      </div>
      <ProfilePage />
      <Footer />
    </div>
  );
};

export default Profile;
