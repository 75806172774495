import React from "react";
import Navbar from "../components/Navbar/Navbar";
import UserNavigation from "../components/Navbar/UserNavigation";
import Footer from "../components/Footer/Footer";
import DashboardPage from "../components/Dashboard/DashboardPage";
const Dashboard = () => {
  return (
    <div>
      <div>
        <Navbar />
        <UserNavigation />
      </div>
      <DashboardPage />
      <Footer />
    </div>
  );
};

export default Dashboard;
