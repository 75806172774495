import React, { useState, useEffect } from "react";
import { DatePicker, Table } from "antd";
import { useTranslation } from "react-i18next";
import "moment/locale/ka";

import {
  StyledBar,
  StyledPickerContainer,
  StyledButton,
  StyledTableContainer,
} from "./Styles/SurrenderStyle";

const { RangePicker } = DatePicker;

const columns = [
  {
    title: "მიღება ჩაბარება",
    dataIndex: "type",
  },
  {
    title: "თარიღი / დრო",
    dataIndex: "date",
  },
  {
    title: "",
    dataIndex: "",
    key: "x",
    render: () => <a>გახსნა</a>,
  },
];

const Surrender = () => {
  const [state, setState] = useState({
    items: [
      {
        id: 1,
        type: "ხელშეკრულება",
        date: "2020-06-12 16:04:58",
      },
      {
        id: 2,
        type: "სერვისის მიწოდების აქტი: ტელეფონი",
        date: "2020-07-09 23:05:24",
      },
      {
        id: 3,
        type: "სერვისის მიწოდების აქტი: ვირტუალური PBX",
        date: "2020-07-24 14:54:33",
      },
      {
        id: 4,
        type: "ხელშეკრულება",
        date: "2020-06-12 16:04:58",
      },
      {
        id: 5,
        type: "სერვისის მიწოდების აქტი: ტელეფონი",
        date: "2020-07-09 23:05:24",
      },
      {
        id: 6,
        type: "სერვისის მიწოდების აქტი: ვირტუალური PBX",
        date: "2020-07-24 14:54:33",
      },
      {
        id: 7,
        type: "ხელშეკრულება",
        date: "2020-06-12 16:04:58",
      },
      {
        id: 8,
        type: "სერვისის მიწოდების აქტი: ტელეფონი",
        date: "2020-07-09 23:05:24",
      },
      {
        id: 9,
        type: "სერვისის მიწოდების აქტი: ვირტუალური PBX",
        date: "2020-07-24 14:54:33",
      },
      {
        id: 1,
        type: "ხელშეკრულება",
        date: "2020-06-12 16:04:58",
      },
      {
        id: 2,
        type: "სერვისის მიწოდების აქტი: ტელეფონი",
        date: "2020-07-09 23:05:24",
      },
      {
        id: 10,
        type: "სერვისის მიწოდების აქტი: ვირტუალური PBX",
        date: "2020-07-24 14:54:33",
      },
      {
        id: 11,
        type: "ხელშეკრულება",
        date: "2020-06-12 16:04:58",
      },
      {
        id: 12,
        type: "სერვისის მიწოდების აქტი: ტელეფონი",
        date: "2020-07-09 23:05:24",
      },
      {
        id: 13,
        type: "სერვისის მიწოდების აქტი: ვირტუალური PBX",
        date: "2020-07-24 14:54:33",
      },
    ],
  });
  const { t, i18n } = useTranslation();
  const locale = {
    lang: {
      locale: t("RangePicker"),
      placeholder: "აირჩიეთ თარიღი",
      yearFormat: "YYYY",
      dateFormat: "D M YYYY",
      dayFormat: "D",
      dateTimeFormat: "D M YYYY HH:mm:ss",
    },
  };
  useEffect(() => {
    const nodes = document.querySelectorAll(".ant-picker-input input");
    nodes[0].placeholder = t("RangePickerPlaceholder0");
    nodes[nodes.length - 1].placeholder = t("RangePickerPlaceholder1");
  }, [localStorage.getItem("switched")]);
  return (
    <div>
      <StyledBar>
        <StyledPickerContainer>
          <div className="picker__box">
            <RangePicker locale={locale} />
          </div>
        </StyledPickerContainer>
        <div>
          <StyledButton large={true}>დადასტურება</StyledButton>
        </div>
      </StyledBar>
      <StyledTableContainer>
        <Table columns={columns} dataSource={state.items} size="middle" />
      </StyledTableContainer>
    </div>
  );
};

export default Surrender;
