import React from "react";
import { Drawer, Button } from "antd";
import { StyledFormGroup } from "./Styles/AddContactDrawer";
const AddInboxContact = ({ showDrawer, onClose, visible, title, number }) => {
  return (
    <>
      <Drawer
        title={title}
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        width="350px"
        destroyOnClose
      >
        <form>
          <StyledFormGroup>
            <label>სახელი</label>
            <input placeholder="შეიყვანეთ სახელი" />
          </StyledFormGroup>
          <StyledFormGroup>
            <label>ნომერი</label>
            <input
              placeholder="შეიყვანეთ მობილურის ნომერი"
              defaultValue={number}
            />
          </StyledFormGroup>
          <StyledFormGroup buttons={true}>
            <button>გაუქმება</button>
            <button>დამახსოვრება</button>
          </StyledFormGroup>
        </form>
      </Drawer>
    </>
  );
};

export default AddInboxContact;
