import React, { useState, useEffect } from "react";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";

import UserNavigationModal from "../Dashboard/UserNavigationModal";
import AddServiceModal from "../Dashboard/AddServiceModal";

import {
  StyledContainer,
  StyledNavbar,
  StyledUl,
  StyledLi,
  StyledTitle,
  StyledAvatar,
} from "./UserNavigationStyles";

import personAvatar from "../../assets/png/personavatar.jpg";
const menu = (
  <Menu>
    <Menu.Item>
      <Link to="profile" rel="noopener noreferrer" style={{ fontSize: "12px" }}>
        პირადი მონაცემები
      </Link>
    </Menu.Item>
    <Menu.Item danger style={{ fontSize: "12px" }}>
      გასვლა
    </Menu.Item>
  </Menu>
);
const UserNavigation = () => {
  const [location, setLocation] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [addServiceModalVisible, setAddServiceModalVisible] = useState(false);

  let hasLocation = useLocation();

  const modalVisibleOnHandler = () => {
    setModalVisible(true);
  };

  const modalVisibleOffHandler = () => {
    setModalVisible(false);
  };

  const addServiceModalVisibleOnHandler = () => {
    setAddServiceModalVisible(true);
  };

  const addServiceModalVisibleOffHandler = () => {
    setAddServiceModalVisible(false);
  };

  useEffect(() => {
    setLocation(hasLocation.pathname);
  }, [location]);
  return (
    <StyledContainer hasUser={true}>
      <UserNavigationModal
        modalVisible={modalVisible}
        modalVisibleOffHandler={modalVisibleOffHandler}
      />
      <AddServiceModal
        addServiceModalVisibleOnHandler={addServiceModalVisibleOnHandler}
        addServiceModalVisibleOffHandler={addServiceModalVisibleOffHandler}
        modalVisible={addServiceModalVisible}
      />
      <StyledNavbar hasUser={true}>
        <StyledUl location={location.length && true}>
          <div>
            {location === "/profile" && (
              <StyledLi>
                <Link to="/dashboard">მთავარი გვერდი</Link>
              </StyledLi>
            )}
          </div>
          <div className="links">
            <StyledLi>
              <Link to="/" onClick={addServiceModalVisibleOnHandler}>
                სერვისის დამატება
              </Link>
            </StyledLi>
            <StyledLi>
              <span onClick={modalVisibleOnHandler}>ნომრის პორტირება</span>
            </StyledLi>
            <StyledTitle>
              <StyledAvatar>
                <img src={personAvatar} />
              </StyledAvatar>
              <div style={{ marginLeft: "10px" }}>
                <Dropdown overlay={menu} trigger={["click"]}>
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    დათო მამასახლისი <DownOutlined />
                  </a>
                </Dropdown>
              </div>
            </StyledTitle>
          </div>
        </StyledUl>
      </StyledNavbar>
    </StyledContainer>
  );
};

export default UserNavigation;
