import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import {
  GlobalOutlined,
  MailOutlined,
  PhoneOutlined,
  FileDoneOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";

const { SubMenu } = Menu;
const ServicesMenu = ({ onOpenChange, setServiceContent, state }) => {
  return (
    <div className="menu">
      <Menu
        mode="inline"
        openKeys={state.openKeys}
        onOpenChange={onOpenChange}
        defaultSelectedKeys={["1"]}
      >
        <SubMenu
          key="sub1"
          onTitleClick={() => setServiceContent(1)}
          title={
            <span>
              <GlobalOutlined />
              <span>სერვისები</span>
            </span>
          }
        >
          <Menu.Item key="1" onClick={() => setServiceContent(1)}>
            ჩემი სერვისები
          </Menu.Item>
          <Menu.Item key="2" onClick={() => setServiceContent(6)}>
            ჩემი შეკვეთები
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="sub2"
          icon={<PhoneOutlined />}
          title="ტელეფონი"
          onTitleClick={() => setServiceContent(2)}
        >
          <Menu.Item key="1" onClick={() => setServiceContent(2)}>
            SMS
          </Menu.Item>
          <Menu.Item key="5" onClick={() => setServiceContent(8)}>
            ამონაწერი
          </Menu.Item>
          <Menu.Item key="6" onClick={() => setServiceContent(9)}>
            ნომრის პარამეტრები
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="sub3"
          icon={<MailOutlined />}
          title="A2P SMS"
          onTitleClick={() => setServiceContent(3)}
        >
          <Menu.Item key="1" onClick={() => setServiceContent(3)}>
            კონკრეტულ პირთან გაგზავნა
          </Menu.Item>
          <Menu.Item key="10" onClick={() => setServiceContent(10)}>
            მასიური გაგზავნა
          </Menu.Item>
          <Menu.Item key="11" onClick={() => setServiceContent(11)}>
            საფირმო სახელწოდება
          </Menu.Item>
          <Menu.Item key="12" onClick={() => setServiceContent(12)}>
            ამონაწერი
          </Menu.Item>
          <Menu.Item key="13" onClick={() => setServiceContent(13)}>
            ბაზის ატვირთვა
          </Menu.Item>
          <Menu.Item key="14" onClick={() => setServiceContent(14)}>
            SMS API
          </Menu.Item>
        </SubMenu>

        <SubMenu
          key="sub4"
          icon={<FileDoneOutlined />}
          title="გადახდები"
          onTitleClick={() => setServiceContent(4)}
        >
          <Menu.Item key="1">გადახდები</Menu.Item>
        </SubMenu>

        <SubMenu
          key="sub5"
          icon={<PaperClipOutlined />}
          title="დოკუმენტები"
          onTitleClick={() => setServiceContent(5)}
        >
          <Menu.Item key="1" onClick={() => setServiceContent(5)}>
            ხელშეკრულება
          </Menu.Item>
          <Menu.Item key="20" onClick={() => setServiceContent(15)}>
            მიღება ჩაბარება
          </Menu.Item>
          <Menu.Item key="21" onClick={() => setServiceContent(16)}>
            ინვოისი
          </Menu.Item>
        </SubMenu>
      </Menu>
    </div>
  );
};

export default ServicesMenu;
