import React, { useState, useEffect, useRef } from "react";
import {
  MessageOutlined,
  DeleteOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { Select, Input } from "antd";
import io from "socket.io-client";
import AddInboxContact from "./AddInboxContact";
import Drawer from "../../../../Drawer/Draw";
import TelephoneDrawer from "./TelephoneDrawer";
import {
  StyledBar,
  StyledButton,
  StyledInbox,
  StyledSent,
  StyledMessages,
  StyledMessage,
  StyledTextArea,
  StyledMessagesContainer,
  StyledInboxContainer,
  StyledInboxBar,
  StyledInboxDelete,
  StyledInboxContacts,
  StyledCheckbox,
  StyledDeleteButton,
  StyledContact,
  StyledContainer,
} from "./Styles/TelephoneStyles";
const { Option } = Select;
const { TextArea, Search } = Input;
const Telephone = () => {
  const [yourId, setYourId] = useState();
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [textAreaLength, setTestAreaLength] = useState(0);
  const [visible, setVisible] = useState(false);
  const [telephoneDrawer, setTelephoneDrawer] = useState(false);
  const [contacts, setContacts] = useState([
    {
      id: 1,
      contactImage: "https://my.telecom1.ge/img/useravatar/noimg.png",
      number: "595607900",
      nickname: "დათო",
      message: "ერთჯერადი კოდი: 9854",
      selected: false,
    },
    {
      id: 2,
      contactImage: "https://my.telecom1.ge/img/useravatar/noimg.png",
      number: "595604879",
      nickname: "გიორგი",
      message: "ერთჯერადი კოდი: 9854",
      selected: false,
    },
    {
      id: 3,
      contactImage: "https://my.telecom1.ge/img/useravatar/noimg.png",
      number: "577487956",
      nickname: "",
      message: "ერთჯერადი კოდი: 9854",
      selected: false,
    },
    {
      id: 4,
      contactImage: "https://my.telecom1.ge/img/useravatar/noimg.png",
      number: "598648798",
      nickname: "",
      message: "ერთჯერადი კოდი: 9854",
      selected: false,
    },
  ]);
  const [contact, setContact] = useState(null);
  const [contactItem, setContactItem] = useState(false);
  const socketRef = useRef();
  useEffect(() => {
    socketRef.current = io.connect("http://localhost:8000/");
    socketRef.current.on("id", (id) => {
      setYourId(id);
    });
    socketRef.current.on("message", (message) => {
      receivedMessage(message);
    });
  }, []);
  function receivedMessage(message) {
    setMessages((oldMsgs) => [...oldMsgs, message]);
  }
  function sendMessage(e) {
    e.preventDefault();
    const messageObject = {
      body: message,
      id: yourId,
    };
    setMessage("");
    socketRef.current.emit("send message", messageObject);
  }
  function handleChange(e) {
    setMessage(e.target.value);
    setTestAreaLength(e.target.value.length);
  }
  const showDrawer = async (item) => {
    setVisible(true);
    setContact(item);
  };
  const onClose = () => {
    setVisible(false);
  };
  const showTelephoneDrawer = () => {
    setTelephoneDrawer(true);
  };
  const closeTelephoneDrawer = () => {
    setTelephoneDrawer(false);
  };
  function onChange(value) {
    console.log(`selected ${value}`);
  }
  const setContactItemHandler = (item) => {
    setContactItem(item);
    closeTelephoneDrawer();
    console.log(contactItem);
  };
  return (
    <StyledContainer>
      <TelephoneDrawer
        showTelephoneDrawer={showTelephoneDrawer}
        closeTelephoneDrawer={closeTelephoneDrawer}
        telephoneDrawer={telephoneDrawer}
        contacts={contacts}
        setContactItemHandler={setContactItemHandler}
      />
      <StyledBar>
        <div className="barx">
          <Select defaultValue="აირჩიეთ ნომერი" style={{ width: "150px" }}>
            <Option value="595607900" style={{ fontSize: "12px" }}>
              595607900
            </Option>
            <Option value="578456794" style={{ fontSize: "12px" }}>
              578456794
            </Option>
          </Select>
          <Input
            placeholder="ადრესატის სახელი ან ნომერი"
            style={{
              width: 215,
              marginLeft: "10px",
              marginTop: "1px",
              padding: "5px",
            }}
            value={
              contactItem === false
                ? null
                : contactItem.nickname
                ? contactItem.nickname
                : contactItem.number
            }
          />
        </div>
        <div>
          <StyledButton large={true} red={true}>
            ახალი SMS
          </StyledButton>
          <StyledButton large={true} red={false} onClick={showTelephoneDrawer}>
            კონტაქტები
          </StyledButton>
        </div>
      </StyledBar>
      <StyledMessagesContainer>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <StyledMessages>
            {messages.map((message, index) => {
              if (message.id === yourId) {
                return (
                  <StyledSent key={index}>
                    <StyledMessage sender={true}>
                      <h4>{message.id}</h4>
                      <p>{message.body}</p>
                    </StyledMessage>
                  </StyledSent>
                );
              }
              return (
                <StyledInbox>
                  <StyledMessage sender={false}>
                    <h4>{message.id}</h4>
                    <p>{message.body}</p>
                  </StyledMessage>
                </StyledInbox>
              );
            })}
          </StyledMessages>
          <form>
            <StyledTextArea
              onChange={handleChange}
              value={message}
              placeholder="აკრიბეთ ტექსტი..."
              maxLength="100"
            />
            <div className="character__length">
              <h4>{textAreaLength} / 100</h4>
              <StyledButton large={true} onClick={sendMessage}>
                გაგზავნა
              </StyledButton>
            </div>
          </form>
        </div>
        <StyledInboxContainer>
          <StyledInboxBar>
            <div className="message">
              <MessageOutlined style={{ fontSize: "20px", color: "#fff" }} />
              <h4>შეტყობინებები</h4>
            </div>
            <Search
              placeholder="ძებნა"
              onSearch={(value) => console.log(value)}
              style={{ width: "50%" }}
            />
          </StyledInboxBar>
          <h4 className="freesms">დარჩენილი უფასო sms რაოდენობა: 100</h4>
          <StyledInboxDelete>
            <StyledCheckbox absolute={false}>
              <label className="container">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    let checked = e.target.checked;
                    setContact(
                      contacts.map((item) => {
                        item.select = checked;
                        return item;
                      })
                    );
                  }}
                />
                ყველას მონიშვნა
                <span className="checkmark"></span>
              </label>
            </StyledCheckbox>
            <StyledDeleteButton>
              <DeleteOutlined /> წაშლა
            </StyledDeleteButton>
          </StyledInboxDelete>
          <StyledInboxContacts>
            {contacts.map((item) => {
              console.log(item.number);
              return (
                <StyledContact key={item.id}>
                  <div className="avatar__content">
                    <StyledCheckbox absolute={true} margin={true}>
                      <label className="container">
                        <input
                          type="checkbox"
                          checked={item.select}
                          onChange={(event) => {
                            let checked = event.target.checked;
                            setContacts(
                              contacts.map((data) => {
                                if (item.id === data.id) {
                                  data.select = checked;
                                }
                                return data;
                              })
                            );
                          }}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </StyledCheckbox>
                    <div className="avatar">
                      <img src={item.contactImage} />
                    </div>
                    <div className="user">
                      <h4>{item.number}</h4>
                      <h5>{item.message}</h5>
                    </div>
                  </div>
                  <button
                    className="user__add"
                    onClick={() => showDrawer(item)}
                  >
                    <UserAddOutlined />
                  </button>
                </StyledContact>
              );
            })}
          </StyledInboxContacts>
        </StyledInboxContainer>
      </StyledMessagesContainer>
      {/* ჯობია ყოველთვის რუთის პირველი კომპონენტი იყოს რადგან მთლიან გვერდზე აკეთებს ოვერლეის */}
      <AddInboxContact
        onClose={onClose}
        visible={visible}
        title="კონტაქტის დამატება"
        number={contact && contact.number}
      />
    </StyledContainer>
  );
};
export default Telephone;
