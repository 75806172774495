import React from "react";

import { useTranslation } from "react-i18next";

import {
  StyledContainer,
  StyledWrapper,
  StyledIconBox,
  StyledIconBoxContainer,
} from "./FooterStyles";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <StyledWrapper>
        <p>{t("FooterLabel")}</p>
        <StyledIconBoxContainer>
          <StyledIconBox rocket={false}>
            <i className="fab fa-facebook-f"></i>
          </StyledIconBox>
          <StyledIconBox rocket={false}>
            <i className="fab fa-linkedin-in"></i>
          </StyledIconBox>
          <StyledIconBox rocket={true}>
            <i className="fas fa-rocket"></i>
            <p>Speed Test</p>
          </StyledIconBox>
        </StyledIconBoxContainer>
      </StyledWrapper>
    </StyledContainer>
  );
};

export default Footer;
