import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import {
  StyledServicesBox,
  StyledServicesTitle,
  StyledPacketList,
  StyledPacket,
  StyledBalanceButton,
  StyledPacketController,
  StyledPacketChange,
  StyledBalanceUp,
  StyledButtonDetails,
} from "./ServiceStyles";

const MyServices = ({ myServicesMap }) => {
  const [services, setServices] = useState([]);
  const [service, setService] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  useEffect(() => {
    setServices(myServicesMap);
  }, []);
  return (
    <>
      {" "}
      {services
        ? services.map((item) => (
            <StyledServicesBox key={item.id}>
              <StyledServicesTitle>
                <img src={item.serviceIcon} alt="img" />
                <h3>{item.serviceTitle}</h3>
              </StyledServicesTitle>
              <StyledPacketList>
                <StyledPacket>
                  <span>პაკეტი:</span> <span>{item.packet}</span>
                </StyledPacket>
                {item.remainedPayment && (
                  <StyledPacket>
                    <span>გადახდამდე დარჩა:</span>{" "}
                    <span>{item.remainedPayment}</span>
                  </StyledPacket>
                )}
                <StyledPacket>
                  <span>ჩართვისი თარიღი:</span>
                  <span>{item.startDate}</span>
                </StyledPacket>
                <StyledPacket>
                  <span>გადახდის მეთოდი:</span>
                  <span>{item.paymentMethod}</span>
                </StyledPacket>
                {item.limit && (
                  <StyledPacket>
                    <span>ლიმიტი:</span>
                    <span>{item.limit}</span>
                  </StyledPacket>
                )}
                <StyledPacket>
                  <span>ბალანსი:</span>
                  <StyledBalanceButton>{item.balance} ₾</StyledBalanceButton>
                </StyledPacket>
                <StyledPacketController>
                  <StyledBalanceUp pay={true}>გადახდა</StyledBalanceUp>
                </StyledPacketController>
                <StyledButtonDetails
                  onClick={() => {
                    setModalVisible(true);
                    setService(item);
                  }}
                >
                  დეტალები
                </StyledButtonDetails>
              </StyledPacketList>
            </StyledServicesBox>
          ))
        : null}
      <Modal
        title="დეტალები"
        centered
        visible={modalVisible}
        footer={null}
        onCancel={() => setModalVisible(false)}
      >
        <StyledServicesTitle>
          <img src={service.serviceIcon} alt="img" />
          <h3>{service.serviceTitle}</h3>
        </StyledServicesTitle>
        <StyledPacketList>
          <StyledPacket>
            <span>პაკეტი:</span> <span>{service.packet}</span>
          </StyledPacket>
          {service.remainedPayment && (
            <StyledPacket>
              <span>გადახდამდე დარჩა:</span>{" "}
              <span>{service.remainedPayment}</span>
            </StyledPacket>
          )}
          <StyledPacket>
            <span>ჩართვისი თარიღი:</span>
            <span>{service.startDate}</span>
          </StyledPacket>
          <StyledPacket>
            <span>გადახდის მეთოდი:</span>
            <span>{service.paymentMethod}</span>
          </StyledPacket>
          {service.limit && (
            <StyledPacket>
              <span>ლიმიტი:</span>
              <span onClick={() => console.log(service.id)}>
                {service.limit}
              </span>
            </StyledPacket>
          )}
          <StyledPacket>
            <span>ბალანსი:</span>
            <StyledBalanceButton>{service.balance} ₾</StyledBalanceButton>
          </StyledPacket>
        </StyledPacketList>
      </Modal>
    </>
  );
};

export default MyServices;
