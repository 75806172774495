import React, { useState } from "react";
import { StyledContainer } from "./Styles/DocumentsStyle";
import { Table } from "antd";

const columns = [
  {
    title: "ხელშეკრულება",
    dataIndex: "type",
  },
  {
    title: "თარიღი / დრო",
    dataIndex: "date",
  },
  {
    title: "",
    dataIndex: "",
    key: "x",
    render: () => <a>გახსნა</a>,
  },
];

const Documents = () => {
  const [state, setState] = useState({
    items: [
      {
        id: 1,
        type: "ხელშეკრულება",
        date: "2020-06-12 16:04:58",
      },
      {
        id: 2,
        type: "სერვისის მიწოდების აქტი: ტელეფონი",
        date: "2020-07-09 23:05:24",
      },
      {
        id: 3,
        type: "სერვისის მიწოდების აქტი: ვირტუალური PBX",
        date: "2020-07-24 14:54:33",
      },
      {
        id: 4,
        type: "ხელშეკრულება",
        date: "2020-06-12 16:04:58",
      },
      {
        id: 5,
        type: "სერვისის მიწოდების აქტი: ტელეფონი",
        date: "2020-07-09 23:05:24",
      },
      {
        id: 6,
        type: "სერვისის მიწოდების აქტი: ვირტუალური PBX",
        date: "2020-07-24 14:54:33",
      },
      {
        id: 7,
        type: "ხელშეკრულება",
        date: "2020-06-12 16:04:58",
      },
      {
        id: 8,
        type: "სერვისის მიწოდების აქტი: ტელეფონი",
        date: "2020-07-09 23:05:24",
      },
      {
        id: 9,
        type: "სერვისის მიწოდების აქტი: ვირტუალური PBX",
        date: "2020-07-24 14:54:33",
      },
      {
        id: 1,
        type: "ხელშეკრულება",
        date: "2020-06-12 16:04:58",
      },
      {
        id: 2,
        type: "სერვისის მიწოდების აქტი: ტელეფონი",
        date: "2020-07-09 23:05:24",
      },
      {
        id: 10,
        type: "სერვისის მიწოდების აქტი: ვირტუალური PBX",
        date: "2020-07-24 14:54:33",
      },
      {
        id: 11,
        type: "ხელშეკრულება",
        date: "2020-06-12 16:04:58",
      },
      {
        id: 12,
        type: "სერვისის მიწოდების აქტი: ტელეფონი",
        date: "2020-07-09 23:05:24",
      },
      {
        id: 13,
        type: "სერვისის მიწოდების აქტი: ვირტუალური PBX",
        date: "2020-07-24 14:54:33",
      },
    ],
  });
  return (
    <StyledContainer>
      <Table columns={columns} dataSource={state.items} size="middle" />
    </StyledContainer>
  );
};

export default Documents;
