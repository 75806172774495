import React from "react";

import { Form, Input, Select, Radio, Table } from "antd";

import Timer from "../HomePage/LoginForm/Timer";

import telephoneImg from "../../assets/png/cellPhone.png";
import pbxImg from "../../assets/png/pbx.png";

import {
  StyledSelectCategory,
  StyledControll,
  StyledTableContainer,
  StyledServicesContainer,
  StyledService,
  StyledSelectServiceButton,
  StyledPinBox,
} from "./AddServiceModalStyle";

const { Option } = Select;
const columns = [
  {
    title: "ნომერი",
    dataIndex: "number",
  },
  {
    title: "ღირებულება",
    dataIndex: "price",
  },
  {
    title: "არჩევა",
    dataIndex: "",
    key: "x",
    render: () => <input type="checkbox" />,
  },
];

export const getServicesStep = ({
  categoryChangeHandler,
  category,
  phoneBase,
  services,
  selectService,
  activeService,
  selectedService,
  showTimer,
  showTimerHandler,
  disableTimer,
}) => [
  {
    title: "",
    id: 1,
    content: (
      <div className="center">
        <StyledSelectCategory>
          <h4>აირჩიეთ კატეგორია</h4>
          <Select
            onChange={categoryChangeHandler}
            defaultValue="აირჩიეთ კატეგორია"
            style={{ fontSize: "12px", width: "180px" }}
          >
            <Option value="1" style={{ fontSize: "12px" }}>
              ტელეფონი
            </Option>
            <Option value="2" style={{ fontSize: "12px" }}>
              ვირტუალური PBX
            </Option>
            <Option value="3" style={{ fontSize: "12px" }}>
              ტელევიზია
            </Option>
          </Select>
        </StyledSelectCategory>
        {category === "1" && (
          <div>
            <StyledControll>
              <div>
                <h4>აირჩიეთ ქალაქი</h4>
                <Select
                  onChange={categoryChangeHandler}
                  defaultValue="თბილისი (32)"
                  style={{ fontSize: "12px", width: "180px" }}
                >
                  <Option value="თბილისი (32)" style={{ fontSize: "12px" }}>
                    თბილისი (32)
                  </Option>
                  <Option value="ქუთაისი (431)" style={{ fontSize: "12px" }}>
                    "ქუთაისი (431)
                  </Option>
                  <Option value="ბათუმი (422)" style={{ fontSize: "12px" }}>
                    ბათუმი (422)
                  </Option>
                </Select>
              </div>
              <div>
                <h4>შეარჩიეთ ნომერი</h4>
                <Input placeholder="მოძებნეთ ნომერი" />
              </div>
              <div>
                <h4>ნომრის ღირებულება</h4>
                <Select
                  onChange={categoryChangeHandler}
                  defaultValue="ყველა"
                  style={{ fontSize: "12px", width: "180px" }}
                >
                  <Option value="5" style={{ fontSize: "12px" }}>
                    5₾
                  </Option>
                  <Option value="10" style={{ fontSize: "12px" }}>
                    10₾
                  </Option>
                  <Option value="15" style={{ fontSize: "12px" }}>
                    15ლ
                  </Option>
                </Select>
              </div>
            </StyledControll>
            <StyledTableContainer>
              <Table columns={columns} dataSource={phoneBase} size="middle" />
            </StyledTableContainer>
          </div>
        )}

        {category === "2" && (
          <>
            {" "}
            <h4 className="chose__number">აირჩიეთ ნომერი</h4>
            <Select
              defaultValue="ყველა"
              style={{ fontSize: "12px", width: "180px" }}
            >
              <Option value="5" style={{ fontSize: "12px" }}>
                5₾
              </Option>
              <Option value="10" style={{ fontSize: "12px" }}>
                10₾
              </Option>
              <Option value="15" style={{ fontSize: "12px" }}>
                15ლ
              </Option>
            </Select>
          </>
        )}
      </div>
    ),
  },
  {
    title: "",
    id: 2,
    content: (
      <StyledServicesContainer oneItem={false}>
        {category === "1" &&
          services.telephone.map((item) => (
            <StyledService
              key={item.id}
              activeService={activeService === item.id}
            >
              <div className="head">
                <img src={telephoneImg} />
                <h4>{item.serviceName}</h4>
              </div>
              <ul>
                <li>
                  <span>სააბონენტო: </span>{" "}
                  <span>
                    {item.subscriber}₾ - {item.dayLimit} დღე
                  </span>
                </li>
                <li>
                  <span>საუბარი შიდა ქსელში: </span>{" "}
                  <span>{item.inTheInternalNetwork}</span>
                </li>
                <li>
                  <span>უფასო საუბარი შიდა ქსელში: </span>{" "}
                  <span>{item.freeCallsOnTheInternalNetwork}</span>
                </li>
                <li>
                  <span>ფიქსირებული ქსელი:</span>
                  <span>{item.fixedNetwork}</span>
                </li>
                <li>
                  <span>მობილური ქსელი:</span>
                  <span>{item.mobileNetwork}</span>
                </li>
                <li>
                  <span>ბონუსი შემომავალ წუთზე:</span>
                  <span>{item.bonus}</span>
                </li>
                <li>
                  <span>SMS</span>
                  <span>{item.sms}</span>
                </li>
                <li>
                  <span>უფასო sms დღეში:</span>
                  <span>{item.freeSmsOfDay}</span>
                </li>
                <li>
                  <span>მინიმალური დეპოზიტი:</span>
                  <span>{item.minimumDeposit}</span>
                </li>
              </ul>
              <div className="select__service">
                <StyledSelectServiceButton
                  activeService={activeService === item.id}
                  onClick={() => {
                    const id = item.id;
                    return selectService({ item, id });
                  }}
                >
                  არჩევა
                </StyledSelectServiceButton>
              </div>
            </StyledService>
          ))}

        {category === "2" &&
          services.bpx.map((item) => (
            <StyledService
              key={item.id}
              activeService={activeService === item.id}
            >
              <div className="head">
                <img src={pbxImg} />
                <h4>{item.serviceName}</h4>
              </div>
              <ul>
                <li>
                  <span>სააბონენტო: </span>{" "}
                  <span>
                    {item.subscriber}₾ - {item.dayLimit} დღე
                  </span>
                </li>
                <li>
                  <span>შიდა ნომრების რაოდენობა: </span>{" "}
                  <span>{item.numbersQuantity}</span>
                </li>
                <li>
                  <span>მრავალარხიანი შიდა ნომერი: </span>{" "}
                  <span>{item.multiChannelQuantity ? "კი" : "არა"}</span>
                </li>
                <li>
                  <span>მისალმება (IVR): </span>{" "}
                  <span>{item.welcome ? "კი" : "არა"}</span>
                </li>
                <li>
                  <span>ზარების მარშრუტიზაცია: </span>{" "}
                  <span>{item.callRouting ? "კი" : "არა"}</span>
                </li>
                <li>
                  <span>ზარების გადამისამართება: </span>{" "}
                  <span>{item.callForwarding ? "კი" : "არა"}</span>
                </li>

                <li>
                  <span>საუბრების ჩაწერა: </span>{" "}
                  <span>{item.recordingConversations ? "კი" : "არა"}</span>
                </li>

                <li>
                  <span>საფირმო სახელწოდებით SMS გაგზავნა: </span>{" "}
                  <span>{item.smsBrandName ? "კი" : "არა"}</span>
                </li>
              </ul>
              <div className="select__service">
                <StyledSelectServiceButton
                  activeService={activeService === item.id}
                  onClick={() => {
                    const id = item.id;
                    return selectService({ item, id });
                  }}
                >
                  არჩევა
                </StyledSelectServiceButton>
              </div>
            </StyledService>
          ))}
      </StyledServicesContainer>
    ),
  },
  {
    title: "",
    id: 3,
    content: (
      <StyledServicesContainer oneItem={true}>
        {category === "1" && (
          <StyledService key={selectedService.id}>
            <div className="head">
              <img src={telephoneImg} />
              <h4>{selectedService.serviceName}</h4>
            </div>
            <ul>
              <li>
                <span>სააბონენტო: </span>{" "}
                <span>
                  {selectedService.subscriber}₾ - {selectedService.dayLimit} დღე
                </span>
              </li>
              <li>
                <span>საუბარი შიდა ქსელში: </span>{" "}
                <span>{selectedService.inTheInternalNetwork}</span>
              </li>
              <li>
                <span>უფასო საუბარი შიდა ქსელში: </span>{" "}
                <span>{selectedService.freeCallsOnTheInternalNetwork}</span>
              </li>
              <li>
                <span>ფიქსირებული ქსელი:</span>
                <span>{selectedService.fixedNetwork}</span>
              </li>
              <li>
                <span>მობილური ქსელი:</span>
                <span>{selectedService.mobileNetwork}</span>
              </li>
              <li>
                <span>ბონუსი შემომავალ წუთზე:</span>
                <span>{selectedService.bonus}</span>
              </li>
              <li>
                <span>SMS</span>
                <span>{selectedService.sms}</span>
              </li>
              <li>
                <span>უფასო sms დღეში:</span>
                <span>{selectedService.freeSmsOfDay}</span>
              </li>
              <li>
                <span>მინიმალური დეპოზიტი:</span>
                <span>{selectedService.minimumDeposit}</span>
              </li>
            </ul>
          </StyledService>
        )}

        {category === "2" && (
          <StyledService activeService={activeService === selectedService.id}>
            <div className="head">
              <img src={pbxImg} />
              <h4>{selectedService.serviceName}</h4>
            </div>
            <ul>
              <li>
                <span>სააბონენტო: </span>{" "}
                <span>
                  {selectedService.subscriber}₾ - {selectedService.dayLimit} დღე
                </span>
              </li>
              <li>
                <span>შიდა ნომრების რაოდენობა: </span>{" "}
                <span>{selectedService.numbersQuantity}</span>
              </li>
              <li>
                <span>მრავალარხიანი შიდა ნომერი: </span>{" "}
                <span>
                  {selectedService.multiChannelQuantity ? "კი" : "არა"}
                </span>
              </li>
              <li>
                <span>მისალმება (IVR): </span>{" "}
                <span>{selectedService.welcome ? "კი" : "არა"}</span>
              </li>
              <li>
                <span>ზარების მარშრუტიზაცია: </span>{" "}
                <span>{selectedService.callRouting ? "კი" : "არა"}</span>
              </li>
              <li>
                <span>ზარების გადამისამართება: </span>{" "}
                <span>{selectedService.callForwarding ? "კი" : "არა"}</span>
              </li>

              <li>
                <span>საუბრების ჩაწერა: </span>{" "}
                <span>
                  {selectedService.recordingConversations ? "კი" : "არა"}
                </span>
              </li>

              <li>
                <span>საფირმო სახელწოდებით SMS გაგზავნა: </span>{" "}
                <span>{selectedService.smsBrandName ? "კი" : "არა"}</span>
              </li>
            </ul>
          </StyledService>
        )}

        <StyledPinBox>
          <h4>PIN-ის მიღების მისამართი:</h4>
          <Timer
            showTimerHandler={showTimerHandler}
            showTimer={showTimer}
            disableTimer={disableTimer}
            modal={true}
          />
        </StyledPinBox>
      </StyledServicesContainer>
    ),
  },
];
